
import ArcIcon from '../assets/ArcIcon.svg';
import Sidebar from '../components/sidebar'
import Plus from "../assets/icons/Plus.svg"
import Button from '../components/button';
import { useState, useEffect } from 'react';
import Modal from '../components/modal'
import { Dialog } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import TextInput from '../components/textInput'
import PasswordInput from '../components/passwordInput'
import DateInput from '../components/dateInput'
import api from '../services/api';
import { useAuth } from '../context/authContext';
import { Bars3Icon } from '@heroicons/react/24/outline';
import { Helmet } from 'react-helmet';

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export default function Estudiantes() {

    const auth = useAuth();
    const [students, setStudents] = useState([]);
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [open, setOpen] = useState(false)
    const [oldEmail, setOldEmail] = useState(undefined);
    const [openConfirmation, setOpenConfirmation] = useState(false)
    const [loading, setLoading] = useState(false)
    const [student, setStudent] = useState({
        firstName: '',
        lastName: '',
        username: '',
        password: '',
        govId: '',
        guardian: undefined,
    });
    const [error, setError] = useState('')
    const handleChange = (field, value) => setStudent((prev) => ({ ...prev, [field]: value }));
    const handleSubmit = async () => {
        setLoading(true);
        setError('');
        if (student.id) {
            if (student.email === oldEmail)
                delete student.email
            api.users.updateMoodle(student.id, student)
                .then((response) => {
                    setStudent((prev) => ({
                        firstName: '',
                        lastName: '',
                        username: '',
                        password: '',
                        govId: '',
                        guardian: prev.guardian,
                    }))
                    api.users.getAllMoodle().then((response) => (setStudents(response), setOpen(false), setLoading(false)))
                })
                .catch((error) => (setError(error.data), setLoading(false)))
        } else {
            api.users.createMoodle(student)
                .then((response) => {
                    setStudent((prev) => ({
                        firstName: '',
                        lastName: '',
                        username: '',
                        password: '',
                        govId: '',
                        guardian: prev.guardian,
                    }))
                    api.users.getAllMoodle().then((response) => (setStudents(response), setOpen(false), setLoading(false)))
                })
                .catch((error) => (setError(error.data), setLoading(false)))
        }
    }

    const handleDelete = async (id) => {
        setError('')
        api.users.deleteMoodle(id)
            .then((response) => {
                setStudent((prev) => ({
                    firstName: '',
                    lastName: '',
                    username: '',
                    password: '',
                    govId: '',
                    guardian: prev.guardian,
                }))
                api.users.getAllMoodle().then((response) => (setStudents(response), setOpenConfirmation(false)))
            })
            .catch((error) => setError(error))
    }

    useEffect(() => {
        if (auth.isAuthenticated) {
            api.users.getMe().then((response) => handleChange('guardian', response.guardianId))
            api.users.getAllMoodle().then((response) => setStudents(response))
        }
    }, [auth])

    const content = (
        <>
            <div>
                <div className="mt-3 text-center px-8">
                    <div className='flex justify-end'>
                        <XMarkIcon onClick={() => (
                            setStudent((prev) => ({
                                firstName: '',
                                lastName: '',
                                username: '',
                                password: '',
                                govId: '',
                                guardian: prev.guardian,
                            })),
                            setError(''),
                            setOpen(false)
                        )} className='h-8 w-8 cursor-pointer' />
                    </div>
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    </Dialog.Title>
                    <div className="mt-2">
                        <div className='mx-auto grid grid-cols-1 sm:grid-cols-2 gap-6 text-start'>
                            <div>
                                <p className='text-[#6B7280] font-medium text-sm'>Nombre del estudiante</p>
                                <TextInput error={error?.firstName} name='firstName' handleChange={handleChange} value={student.firstName} className='border-primary rounded-md z-20' placeholder='Nombre del estudiante' />
                            </div>
                            <div>
                                <p className='text-[#6B7280] font-medium text-sm'>Apellidos del estudiante</p>
                                <TextInput error={error?.lastName} name='lastName' handleChange={handleChange} value={student.lastName} className='border-primary rounded-md' placeholder='Apellidos del estudiante' />
                            </div>
                            <div>
                                <p className='text-[#6B7280] font-medium text-sm'>Fecha de nacimiento</p>
                                <DateInput error={error?.birthDate} name='birthDate' handleChange={handleChange} value={student.birthDate} className='border-primary rounded-md' />
                            </div>
                            <div>
                                <p className='text-[#6B7280] font-medium text-sm'>Escuela o colegio</p>
                                <TextInput error={error?.school} name='school' handleChange={handleChange} value={student.school} className='border-primary rounded-md' placeholder='Escuela o colegio del estudiante' />
                            </div>
                            <div>
                                <p className='text-[#6B7280] font-medium text-sm'>Nombre de usuario del estudiante (para Moodle)</p>
                                <TextInput error={error?.username} name='username' handleChange={handleChange} value={student.username} className='border-primary rounded-md' placeholder='Asigna el nombre de usuario' />
                            </div>
                            <div>
                                <p className='text-[#6B7280] font-medium text-sm'>Correo electrónico del estudiante (para Moodle)</p>
                                <TextInput error={error?.email} name='email' handleChange={handleChange} value={student.email} className='border-primary rounded-md' placeholder='Correo del estudiante' />
                            </div>
                            <div>
                                <p className='text-[#6B7280] font-medium text-sm'>Contraseña (para el Moodle)</p>
                                <PasswordInput error={error?.password} name='password' handleChange={handleChange} value={student.password} className='border-primary rounded-md' placeholder='Asigna la contraseña' />
                            </div>
                            <div>
                                <p className='text-[#6B7280] font-medium text-sm'>Cédula del estudiante</p>
                                <TextInput error={error?.govId} name='govId' handleChange={handleChange} value={student.govId} className='border-primary rounded-md' placeholder='Cédula del estudiante' />
                            </div>
                        </div>
                        <div className='flex mx-auto w-fit gap-6'>
                            {student.id ?
                                <Button onClick={() => handleSubmit()} content={loading ? <div className="w-5 h-5 mx-5 border-b-2 border-primary rounded-full animate-spin" /> : 'Guardar Cambios'} className='bg-white border-primary text-primary w-fit mt-6 flex mx-auto' />
                                : <Button onClick={() => handleSubmit()} content={loading ? <div className="w-5 h-5 mx-5 border-b-2 border-primary rounded-full animate-spin" /> : <div className='flex gap-4 h-fit sm:h-auto whitespace-nowrap'><img src={Plus} alt='Plus' />Añadir estudiante</div>} className='bg-white border-primary text-primary w-fit mt-6 flex mx-auto' />
                            }
                            {student.id ? <Button onClick={() => (setOpenConfirmation(true), setOpen(false))} content='Eliminar estudiante' className='bg-white border-red text-red w-fit mt-6 flex mx-auto' /> : null}
                        </div>
                        <p className='text-[#6B7280] font-medium text-sm mt-6'>Si tiene inconvenientes, contactar con Atención al Cliente: </p>
                        <a href='https://wa.me/+595986366060' target="_blank" rel="noreferrer noopener" key='Solicitar Ayuda' className="flex flex-row">
                            <p className='text-[#6B7280] font-medium text-sm mt-2 mx-auto'>+595986366060</p>
                        </a>
                    </div>
                </div>
            </div>
        </>
    )

    const confirmDelete = (
        <>
            <div>
                <div className="mt-3 text-center px-8">
                    <div className='flex justify-end'>
                        <XMarkIcon onClick={() => (setOpenConfirmation(false), setOpen(true))} className='h-8 w-8 cursor-pointer' />
                    </div>
                    <Dialog.Title as="h3" className="text-lg mt-2 font-medium leading-6 text-gray-900">
                        <p>¿Está seguro que desea eliminar a este estudiante?</p>
                    </Dialog.Title>
                    <div className="mt-2">
                        <div className='flex mx-auto w-fit gap-6'>
                            <Button onClick={() => handleDelete(student.id)} content='Aceptar' className='bg-white border-primary text-primary w-fit mt-6 flex mx-auto' />
                            <Button onClick={() => (setOpenConfirmation(false), setOpen(true))} content='Cancelar' className='bg-white border-red text-red w-fit mt-6 flex mx-auto' />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )


    return (auth.isAuthenticated ?
        <>
            <Helmet>
                <meta property="og:image" content={`${process.env.REACT_APP_DOMAIN}/Thumbnail.png`} />
            </Helmet>
            <Modal open={open} setOpen={setOpen} onClose={() => (
                setStudent((prev) => ({
                    firstName: '',
                    lastName: '',
                    username: '',
                    password: '',
                    govId: '',
                    guardian: prev.guardian,
                })),
                setError(''),
                setOpen(false)
            )} content={content} />
            <Modal containerClassName='max-w-xl' open={openConfirmation} setOpen={setOpenConfirmation} content={confirmDelete} onClose={() => (setOpenConfirmation(false), setOpen(true))} />
            <img className='hidden sm:block absolute right-[20%] top-[25%]' src={ArcIcon} alt='Arc' />
            <div className='bg-white min-h-screen flex flex-col sm:flex-row'>
                <Sidebar selected={4} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <div className="sticky top-0 z-20 md:hidden pl-1 pt-1 sm:pl-3 h-fit sm:pt-3 bg-white-off">
                    <button
                        type="button"
                        className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                        onClick={() => setSidebarOpen(true)}
                    >
                        <span className="sr-only">Open sidebar</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                <div className='flex flex-col w-full'>
                    <p className='text-[#1D6877] text-3xl font-bold mt-10 sm:mt-24 ml-6'>Lista de Estudiantes</p>
                    <div className='bg-white-off bg-opacity-10 rounded-lg py-10 px-8 flex flex-col w-[95%] h-full my-6'>
                        <div className='grid grid-cols-2 gap-6 sm:w-5/6'>
                            {students.map((element) => (
                                <div onClick={() => (setStudent(element), setOldEmail(element.email), setOpen(true))} className='grid col-span-2 sm:col-span-1 cursor-pointer grid-cols-3 gap-8 rounded-3xl shadow-lg bg-white py-10 px-3 z-10' key={element.id}>
                                    <div className='ml-8 col-span-2'>
                                        <p className='font-semibold text-2xl'>{element.firstName + ' ' + element.lastName}</p>
                                        <div className='flex mt-2'>
                                            <p className='text-primary font-semibold'>Documento: </p>
                                            <p className='ml-2'>{element.govId}</p>
                                        </div>
                                    </div>
                                    <div className='col-span-1 text-center py-4 justify-center flex'>
                                        <p className='font-semibold text-primary'>Editar</p>
                                    </div>
                                </div>
                            ))}
                            <div onClick={() => setOpen(true)} className='col-span-2 rounded-3xl justify-center gap-6 shadow-lg bg-white py-10 flex cursor-pointer' key='add'>
                                <img src={Plus} alt='Plus' />
                                <p className='text-primary font-semibold'>Añadir nuevo estudiante</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
        :
        <>
            <Helmet>
                <meta property="og:image" content={`${process.env.REACT_APP_DOMAIN}/Thumbnail.png`} />
            </Helmet>
            <div className='bg-white min-h-screen flex justify-center'>
                <div className="w-10 h-10 mx-5 border-b-2 my-auto border-primary rounded-full animate-spin" />
            </div>
        </>
    )
}
