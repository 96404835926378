import { useState } from 'react'
import Button from '../components/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import PiensaLogo from '../assets/PiensaLogo.svg';
import VectorIcon from '../assets/VectorIcon.svg';
import SphereIcon from '../assets/SphereIcon.svg';
import AxisIcon from '../assets/AxisIcon.png';
import LightbulbIcon from '../assets/LightbulbIcon.png';
import TextInput from '../components/textInput'
import PasswordInput from '../components/passwordInput'
import { useAuth } from '../context/authContext'
import { useNavigate, Link } from 'react-router-dom'
import api from '../services/api';
import { Helmet } from 'react-helmet';
import ReCAPTCHA from "react-google-recaptcha";

export default function Registro() {

    const auth = useAuth();
    const navigate = useNavigate();
    const [user, setUser] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
    });
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [captcha, setCaptcha] = useState(false);
    const handleChange = (field, value) => setUser((prev) => ({ ...prev, [field]: value }));

    const handleLogin = () => {
        auth.signIn(user.email, user.password).then((response) => {
            navigate('/cursos')
        }).catch((e) => {
            setLoading(false)
            setError(e.detail)
        });
    }

    const handleSubmit = async () => {
        setLoading(true);
        setError('');
        if (captcha)
            api.users.create(user)
                .then((response) => {
                    handleLogin(response.id)
                })
                .catch((error) => (setError(error.data), setLoading(false)))
        else {
            setError({ recaptcha: 'Favor verificar que no es un robot.' })
            setLoading(false);
        }
    }

    function onChange(value) {
        setCaptcha(true);
    }

    return (!auth.isAuthenticated ?
        <>
            <Helmet>
                <meta property="og:image" content={`${process.env.REACT_APP_DOMAIN}/Thumbnail.png`} />
            </Helmet>
            <img className='hidden sm:block absolute left-[20%] top-[20%]' src={VectorIcon} alt='Vector' />
            <img className='hidden sm:block absolute right-[25%] top-[10%]' src={SphereIcon} alt='Sphere' />
            <img className='hidden sm:block absolute right-[15%] bottom-[5%]' src={AxisIcon} alt='Axis' />
            <img className='hidden sm:block absolute left-[10%] bottom-[5%]' src={LightbulbIcon} alt='Lightbulb' />
            <a href='https://piensa.la/' className='border-red text-red absolute top-4 sm:top-16 left-4 sm:left-16 py-2 px-7 justify-center align-middle inline-flex border rounded-md shadow-sm'><div className='flex gap-2'><div className='w-5'><FontAwesomeIcon icon={faArrowLeft} size="xs" /></div><p>Volver a piensa.la</p></div></a>
            <div className='bg-gray min-h-screen pb-4 pt-20 sm:pt-16'>
                <div className='flex justify-center mx-auto'>
                    <img src={PiensaLogo} alt='Piensa Logo' />
                </div>
                <p className='text-primary text-3xl font-bold text-center mt-4'>Registrate aquí</p>
                <div className='flex flex-col mt-2 sm:mt-0 sm:flex-row text-center sm:text-left justify-center gap-1 sm:gap-3'>
                    <p>¿Ya tenés cuenta?</p>
                    <Link to='/' className='text-red'>Inicia sesión</Link>
                </div>
                <div className='mt-8 px-6 sm:px-0 sm:w-1/3 mx-auto flex flex-col gap-2'>
                    <p className='text-[#6B7280] font-medium text-sm'>Nombre (Padre o tutor)</p>
                    <TextInput error={error?.firstName} handleChange={handleChange} name='firstName' className='border-primary rounded-md' placeholder='Escribí tu nombre' />
                    <p className='text-[#6B7280] font-medium text-sm'>Apellidos (Padre o tutor)</p>
                    <TextInput error={error?.lastName} handleChange={handleChange} name='lastName' className='border-primary rounded-md' placeholder='Escribí tus apellidos' />
                    <p className='text-[#6B7280] font-medium text-sm'>Correo</p>
                    <TextInput error={error?.email} handleChange={handleChange} name='email' className='border-primary rounded-md' placeholder='Escribí el correo que utilizas' />
                    <p className='text-[#6B7280] font-medium text-sm'>Contraseña</p>
                    <PasswordInput error={error?.password} handleChange={handleChange} name='password' eyeClassname='rounded-tr-md border-2 border-primary border-l-0' className='border-primary rounded-md' placeholder='Creá una contraseña' />
                    <ReCAPTCHA
                        className='h-fit mx-auto mt-2'
                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                        onChange={onChange}
                    />
                    {error.recaptcha && <p className='text-red text-sm'>{error.recaptcha}</p>}
                    <Button onClick={() => handleSubmit()} content={loading ? <div className="w-5 h-5 mx-5 border-b-2 border-white rounded-full animate-spin" /> : 'Siguiente paso'} className='bg-red border-red text-white w-full mt-2 py-2 px-7 justify-center align-middle inline-flex border rounded-md shadow-sm' />
                </div>
            </div>
        </>
        :
        <>
            <Helmet>
                <meta property="og:image" content={`${process.env.REACT_APP_DOMAIN}/Thumbnail.png`} />
            </Helmet>
            <div className='bg-white min-h-screen flex justify-center'>
                <div className="w-10 h-10 mx-5 border-b-2 my-auto border-primary rounded-full animate-spin" />
            </div>
        </>
    )
}
