import { useState } from 'react'
import Button from '../components/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import PiensaLogo from '../assets/PiensaLogo.svg';
import VectorIcon from '../assets/VectorIcon.svg';
import SphereIcon from '../assets/SphereIcon.svg';
import AxisIcon from '../assets/AxisIcon.png';
import LightbulbIcon from '../assets/LightbulbIcon.png';
import TextInput from '../components/textInput'
import PasswordInput from '../components/passwordInput'
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../context/authContext';
import { Helmet } from 'react-helmet';

export default function Home() {

  const auth = useAuth();
  const navigate = useNavigate();
  const [login, setLogin] = useState({
    email: '',
    password: '',
  })
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const handleChange = (field, value) => setLogin((prev) => ({ ...prev, [field]: value }));
  const handleError = (field, value) => setError((prev) => ({ ...prev, [field]: value }));

  const handleLogin = () => {
    setLoading(true)
    auth.signIn(login.email, login.password).then((response) => {
      navigate('/cursos')
    }).catch((e) => {
      setLoading(false)
      setError(e.detail)
    });
  }

  return (!auth.isAuthenticated ?
    <>
      <Helmet>
        <meta property="og:image" content={`${process.env.REACT_APP_DOMAIN}/Thumbnail.png`} />
      </Helmet>
      <img className='hidden sm:block absolute left-[20%] top-[20%]' src={VectorIcon} alt='Vector' />
      <img className='hidden sm:block absolute right-[25%] top-[10%]' src={SphereIcon} alt='Sphere' />
      <img className='hidden sm:block absolute right-[15%] bottom-[5%]' src={AxisIcon} alt='Axis' />
      <img className='hidden sm:block absolute left-[10%] bottom-[5%]' src={LightbulbIcon} alt='Lightbulb' />
      <div className='bg-gray min-h-screen px-8'>
        <a href='https://piensa.la/' className='border-red text-red absolute top-4 sm:top-16 left-4 sm:left-16 py-2 px-7 justify-center align-middle inline-flex border rounded-md shadow-sm'><div className='flex gap-2'><div className='w-5'><FontAwesomeIcon icon={faArrowLeft} size="xs" /></div><p>Volver a piensa.la</p></div></a>
        <p className='text-primary block sm:hidden text-2xl font-bold text-center pt-20 sm:pt-16'>Accede a la plataforma y administra tus cursos</p>
        <p className='text-primary hidden sm:block text-3xl font-bold text-center pt-20 sm:pt-16'>Accede a la plataforma y administra <br /> tus cursos</p>
        <div className='w-32 h-32 mx-auto mt-10'>
          <img src={PiensaLogo} alt='Piensa Logo' />
        </div>
        <p className='text-primary text-2xl sm:text-3xl font-extrabold text-center -mt-12 sm:-mt-8'>Iniciar sesión</p>
        <div className='flex flex-col sm:flex-row gap-2 justify-center'>
          <p className='text-center'>¿Aún no posees una cuenta?</p><Link className='text-red text-center sm:text-start' to='/registro'>Registrate aqui</Link>
        </div>
        <div className='mt-4 sm:mt-8 sm:w-1/3 mx-auto'>
          <TextInput name='email' value={login.email} handleChange={handleChange} className='rounded-t' placeholder='Ingresa tu correo' />
          <PasswordInput name='password' value={login.password} handleChange={handleChange} className='rounded-b' placeholder='Ingresa tu contraseña' />
          <Link to='/recuperacion'><p className='text-primary text-center mt-4 sm:mt-8 font-medium' >¿Olvidaste tu contraseña?</p></Link>
          <Button onClick={() => handleLogin()} content={loading ? <div className="w-5 h-5 mx-5 border-b-2 border-white rounded-full animate-spin" /> : 'Iniciar sesión'} className='bg-primary border-primary text-white w-full mt-6 py-2 px-7 justify-center align-middle inline-flex border rounded-md shadow-sm' />
          {error !== '' && <p className='text-red mt-2 text-center'>{error}</p>}
        </div>
      </div>
    </>
    :
    <>
      <Helmet>
        <meta property="og:image" content={`${process.env.REACT_APP_DOMAIN}/Thumbnail.png`} />
      </Helmet>
      <div className='bg-white min-h-screen flex justify-center'>
        <div className="w-10 h-10 mx-5 border-b-2 my-auto border-primary rounded-full animate-spin" />
      </div>
    </>
  )
}
