import { useState, useEffect } from 'react'
import Button from '../components/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import PiensaLogo from '../assets/PiensaLogo.svg';
import VectorIcon from '../assets/VectorIcon.svg';
import SphereIcon from '../assets/SphereIcon.svg';
import AxisIcon from '../assets/AxisIcon.png';
import LightbulbIcon from '../assets/LightbulbIcon.png';
import TextInput from '../components/textInput'
import SelectInput from '../components/selectInput'
import { useAuth } from '../context/authContext';
import { useNavigate, Link } from 'react-router-dom';
import api from '../services/api';
import { Helmet } from 'react-helmet';

const countries = [
    { name: 'PARAGUAY', value: 0 },
    { name: 'CHILE', value: 1 },
    { name: 'ARGENTINA', value: 2 },
    { name: 'URUGUAY', value: 3 },
    { name: 'OTHER', value: 4 },
]

const options = [
    { name: 'Cédula', value: 0 },
    { name: 'Pasaporte', value: 1 },
]

const billingFields = [
    'govId',
    'phone',
    'city',
    'address',
    'socialReason',
    'ruc',
]

export default function Facturacion() {

    const auth = useAuth();
    const navigate = useNavigate();
    const [user, setUser] = useState({})
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const handleChange = (field, value) => setUser((prev) => ({ ...prev, [field]: value }));
    const handleSubmit = async () => {
        setLoading(true)
        billingFields.forEach((element) => {
            if (user[element] === '') {
                delete user[element]
            }
        })
        api.users.update(user.id, user)
            .then((response) => {
                navigate('/resumen')
            })
            .catch((error) => (setError(error), setLoading(false)))
    }


    useEffect(() => {
        if (auth.isAuthenticated) {
            api.users.getMe().then((response) => setUser((prev) => ({ ...prev, ...response })))
        }
    }, [auth])

    useEffect(() => {
        if (!user.country) {
            handleChange('country', 0)
        }
        if (!user.documentType) {
            handleChange('documentType', 0)
        }
    }, [user.country, user.documentType])

    return (auth.isAuthenticated ?
        <>
            <Helmet>
                <meta property="og:image" content={`${process.env.REACT_APP_DOMAIN}/Thumbnail.png`} />
            </Helmet>
            <img className='hidden sm:block z-0 absolute left-[20%] top-[20%]' src={VectorIcon} alt='Vector' />
            <img className='hidden sm:block z-0 absolute right-[25%] top-[10%]' src={SphereIcon} alt='Sphere' />
            <img className='hidden sm:block z-0 absolute right-[15%] bottom-[5%]' src={AxisIcon} alt='Axis' />
            <img className='hidden sm:block z-0 absolute left-[10%] bottom-[5%]' src={LightbulbIcon} alt='Lightbulb' />
            <div className='bg-gray min-h-screen pb-10 sm:pb-0'>
                <Link to='/seleccion' className='border-red text-red absolute left-4 sm:left-10 top-4 sm:top-16 py-2 px-7 justify-center align-middle inline-flex border rounded-md shadow-sm'><div className='flex gap-2'><div className='w-5'><FontAwesomeIcon icon={faArrowLeft} size="xs" /></div><p>Volver</p></div></Link>
                <div className='flex justify-center mx-auto pt-20 sm:pt-10'>
                    <img src={PiensaLogo} alt='Piensa Logo' />
                </div>
                <p className='text-primary text-3xl font-bold text-center mt-4'>Añadí tus datos de facturación</p>
                <div className='relative mt-8 px-8 sm:px-0 sm:w-1/2 mx-auto grid grid-cols-2 gap-6 z-10'>
                    <div className='col-span-2 sm:col-span-1'>
                        <p className='text-[#6B7280] font-medium text-sm'>Nombres (Padre o Tutor)</p>
                        <TextInput disabled={true} name='firstName' value={user?.firstName} handleChange={handleChange} className='border-primary rounded-md z-20' placeholder='Escribe tu nombre completo' />
                    </div>
                    <div className='col-span-2 sm:col-span-1'>
                        <p className='text-[#6B7280] font-medium text-sm'>Apellidos (Padre o Tutor)</p>
                        <TextInput disabled={true} name='lastName' value={user?.lastName} handleChange={handleChange} className='border-primary rounded-md z-20' placeholder='Escribe tus apellidos' />
                    </div>
                    <div className='col-span-2 sm:col-span-1'>
                        <p className='text-[#6B7280] font-medium text-sm'>Tipo de documento:</p>
                        <SelectInput value={user?.documentType} name='documentType' handleChange={handleChange} className='border-primary rounded-md' placeholder='Cedula' options={options.map((element, idx) => (<option key={idx} value={element.value} label={element.name}>{element.name}</option>))} />
                    </div>
                    <div className='col-span-2 sm:col-span-1'>
                        <p className='text-[#6B7280] font-medium text-sm'>Número de documento</p>
                        <TextInput error={error?.govId} value={user?.govId} name='govId' handleChange={handleChange} className='border-primary rounded-md' placeholder='5243253' />
                    </div>
                    <div className='col-span-2 sm:col-span-1'>
                        <p className='text-[#6B7280] font-medium text-sm'>Razón social</p>
                        <TextInput error={error?.socialReason} value={user?.socialReason} name='socialReason' handleChange={handleChange} className='border-primary rounded-md' placeholder='Hnos. Benitez' />
                    </div>
                    <div className='col-span-2 sm:col-span-1'>
                        <p className='text-[#6B7280] font-medium text-sm'>RUC</p>
                        <TextInput error={error?.ruc} value={user?.ruc} name='ruc' handleChange={handleChange} className='border-primary rounded-md' placeholder='5243253-3' />
                    </div>
                    <div className='col-span-2 sm:col-span-1'>
                        <p className='text-[#6B7280] font-medium text-sm'>Numero de teléfono</p>
                        <TextInput error={error?.phone} value={user?.phone} name='phone' handleChange={handleChange} className='border-primary rounded-md' placeholder='+0 000 0000' />
                    </div>
                    <div className='col-span-2 sm:col-span-1'>
                        <p className='text-[#6B7280] font-medium text-sm'>País</p>
                        <SelectInput value={user?.country} name='country' handleChange={handleChange} className='border-primary rounded-md' placeholder='Selecciona tu país' options={countries.map((element, idx) => (<option key={idx} name={element.name} value={element.value}>{element.name}</option>))} />
                    </div>
                    <div className='col-span-2 sm:col-span-1'>
                        <p className='text-[#6B7280] font-medium text-sm'>Ciudad</p>
                        <TextInput error={error?.city} value={user?.city} name='city' handleChange={handleChange} className='border-primary rounded-md' placeholder='Asunción' />
                    </div>
                    <div className='col-span-2 sm:col-span-1'>
                        <p className='text-[#6B7280] font-medium text-sm'>Dirección</p>
                        <TextInput error={error?.address} value={user?.address} name='address' handleChange={handleChange} className='border-primary rounded-md' placeholder='Dirección' />
                    </div>
                    <Button onClick={() => handleSubmit()} content={loading ? <div className="w-5 h-5 mx-5 border-b-2 border-white rounded-full animate-spin" /> : 'Siguiente'} className='bg-red border-red z-10 text-white col-span-2 h-fit mt-5 py-2 px-7 justify-center align-middle inline-flex border rounded-md shadow-sm' />
                </div>
            </div>
        </>
        :
        <>
            <Helmet>
                <meta property="og:image" content={`${process.env.REACT_APP_DOMAIN}/Thumbnail.png`} />
            </Helmet>
            <div className='bg-white min-h-screen flex justify-center'>
                <div className="w-10 h-10 mx-5 border-b-2 my-auto border-primary rounded-full animate-spin" />
            </div>
        </>
    )
}
