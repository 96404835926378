import Button from '../components/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import PiensaLogo from '../assets/PiensaLogo.svg';
import VectorIcon from '../assets/VectorIcon.svg';
import SphereIcon from '../assets/SphereIcon.svg';
import AxisIcon from '../assets/AxisIcon.png';
import LightbulbIcon from '../assets/LightbulbIcon.png';
import Check from '../assets/icons/Check.svg';
import Plus from '../assets/icons/Plus.svg'
import TextInput from '../components/textInput'
import { useEffect, useState } from 'react'
import PasswordInput from '../components/passwordInput'
import Modal from '../components/modal'
import { Dialog } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import DateInput from '../components/dateInput'
import { useAuth } from '../context/authContext'
import api from '../services/api';
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default function Seleccion() {

    const auth = useAuth();
    const [student, setStudent] = useState({
        firstName: '',
        lastName: '',
        username: '',
        password: '',
        govId: '',
        selected: true,
        guardian: undefined,
    });
    const [students, setStudents] = useState([]);
    const [selected, setSelected] = useState(-1);
    const [oldEmail, setOldEmail] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false)
    const [openConfirmation, setOpenConfirmation] = useState(false)
    const [error, setError] = useState('');
    const handleChange = (field, value) => setStudent((prev) => ({ ...prev, [field]: value }));
    // const handleElement = (value) => setSelected((prev) => {
    //     let obj = null
    //     if (prev.find((element) => element === value)) {
    //         obj = prev.filter((element) => element !== value).slice()
    //         return (obj)
    //     } else {
    //         obj = prev.slice()
    //         obj.push(value)
    //         return (obj)
    //     }
    // })
    const handleSubmit = async () => {
        setLoading(true);
        setError('');
        if (student.id) {
            if (student.email === oldEmail)
                delete student.email
            api.users.updateMoodle(student.id, student)
                .then((response) => {
                    setStudent((prev) => ({
                        firstName: '',
                        lastName: '',
                        username: '',
                        password: '',
                        govId: '',
                        guardian: prev.guardian,
                    }))
                    api.users.getAllMoodle().then((response) => (setStudents(response), setOpen(false), setLoading(false)))
                })
                .catch((error) => (setError(error.data), setLoading(false)))
        } else {
            api.users.createMoodle(student)
                .then((response) => {
                    setStudent((prev) => ({
                        firstName: '',
                        lastName: '',
                        username: '',
                        password: '',
                        govId: '',
                        guardian: prev.guardian,
                    }))
                    api.users.getAllMoodle().then((response) => (setStudents(response), setOpen(false), setLoading(false)))
                })
                .catch((error) => (setError(error.data), setLoading(false)))
        }
    }

    const handleDelete = async (id) => {
        setError('')
        api.users.deleteMoodle(id)
            .then((response) => {
                setStudent((prev) => ({
                    firstName: '',
                    lastName: '',
                    username: '',
                    password: '',
                    govId: '',
                    guardian: prev.guardian,
                }))
                api.users.getAllMoodle().then((response) => (setStudents(response), setOpenConfirmation(false), setLoading(false)))
            })
            .catch((error) => setError(error.data))
    }

    useEffect(() => {
        if (auth.isAuthenticated) {
            api.users.getMe().then((response) => handleChange('guardian', response.guardianId))
            api.users.getAllMoodle().then((response) => setStudents(response))
        }
    }, [auth])

    useEffect(() => {
        sessionStorage.setItem('selected', selected)
    }, [selected])

    const content = (
        <>
            <div>
                <div className="mt-3 text-center px-8">
                    <div className='flex justify-end'>
                        <XMarkIcon onClick={() => (
                            setStudent((prev) => ({
                                firstName: '',
                                lastName: '',
                                username: '',
                                password: '',
                                govId: '',
                                guardian: prev.guardian,
                            })),
                            setError(''),
                            setOpen(false)
                        )} className='h-8 w-8 cursor-pointer' />
                    </div>
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    </Dialog.Title>
                    <div className="mt-2">
                        <div className='mx-auto grid grid-cols-1 sm:grid-cols-2 gap-6 text-start'>
                            <div>
                                <p className='text-[#6B7280] font-medium text-sm'>Nombre del estudiante</p>
                                <TextInput error={error?.firstName} name='firstName' handleChange={handleChange} value={student.firstName} className='border-primary rounded-md z-20' placeholder='Nombre del estudiante' />
                            </div>
                            <div>
                                <p className='text-[#6B7280] font-medium text-sm'>Apellidos del estudiante</p>
                                <TextInput error={error?.lastName} name='lastName' handleChange={handleChange} value={student.lastName} className='border-primary rounded-md' placeholder='Apellidos del estudiante' />
                            </div>
                            <div>
                                <p className='text-[#6B7280] font-medium text-sm'>Fecha de nacimiento</p>
                                <DateInput error={error?.birthDate} name='birthDate' handleChange={handleChange} value={student.birthDate} className='border-primary rounded-md' />
                            </div>
                            <div>
                                <p className='text-[#6B7280] font-medium text-sm'>Escuela o colegio</p>
                                <TextInput error={error?.school} name='school' handleChange={handleChange} value={student.school} className='border-primary rounded-md' placeholder='Escuela o colegio del estudiante' />
                            </div>
                            <div>
                                <p className='text-[#6B7280] font-medium text-sm'>Nombre de usuario del estudiante (para Moodle)</p>
                                <TextInput error={error?.username} name='username' handleChange={handleChange} value={student.username} className='border-primary rounded-md' placeholder='Asigna el nombre de usuario' />
                            </div>
                            <div>
                                <p className='text-[#6B7280] font-medium text-sm'>Correo electrónico del estudiante (para Moodle)</p>
                                <TextInput error={error?.email} name='email' handleChange={handleChange} value={student.email} className='border-primary rounded-md' placeholder='Correo del estudiante' />
                            </div>
                            <div>
                                <p className='text-[#6B7280] font-medium text-sm'>Contraseña (para el Moodle)</p>
                                <PasswordInput error={error?.password} name='password' handleChange={handleChange} value={student.password} className='border-primary rounded-md' placeholder='Asigna la contraseña' />
                            </div>
                            <div>
                                <p className='text-[#6B7280] font-medium text-sm'>Cédula del estudiante</p>
                                <TextInput error={error?.govId} name='govId' handleChange={handleChange} value={student.govId} className='border-primary rounded-md' placeholder='Cédula del estudiante' />
                            </div>
                        </div>
                        <div className='flex mx-auto w-fit gap-6'>
                            {student.id ?
                                <Button onClick={() => handleSubmit()} content={loading ? <div className="w-5 h-5 mx-5 border-b-2 border-primary rounded-full animate-spin" /> : 'Guardar Cambios'} className='bg-white border-primary text-primary w-fit mt-6 flex mx-auto' />
                                : <Button onClick={() => handleSubmit()} content={loading ? <div className="w-5 h-5 mx-5 border-b-2 border-primary rounded-full animate-spin" /> : <div className='flex gap-4 whitespace-nowrap'><img src={Plus} alt='Plus' />Añadir estudiante</div>} className='bg-white border-primary text-primary w-fit mt-6 flex mx-auto' />
                            }
                            {student.id ? <Button onClick={() => (setOpenConfirmation(true), setOpen(false))} content='Eliminar estudiante' className='bg-white border-red text-red w-fit mt-6 flex mx-auto' /> : null}
                        </div>
                        <p className='text-[#6B7280] font-medium text-sm mt-6'>Si tiene inconvenientes, contactar con Atención al Cliente: </p>
                        <a href='https://wa.me/+595986366060' target="_blank" rel="noreferrer noopener" key='Solicitar Ayuda' className="flex flex-row">
                            <p className='text-[#6B7280] font-medium text-sm mt-2 mx-auto'>+595986366060</p>
                        </a>
                    </div>
                </div>
            </div>
        </>
    )

    const confirmDelete = (
        <>
            <div>
                <div className="mt-3 text-center px-8">
                    <div className='flex justify-end'>
                        <XMarkIcon onClick={() => (setOpenConfirmation(false), setOpen(true))} className='h-8 w-8 cursor-pointer' />
                    </div>
                    <Dialog.Title as="h3" className="text-lg mt-2 font-medium leading-6 text-gray-900">
                        <p>¿Está seguro que desea eliminar a este estudiante?</p>
                    </Dialog.Title>
                    <div className="mt-2">
                        <div className='flex mx-auto w-fit gap-6'>
                            <Button onClick={() => handleDelete(student.id)} content='Aceptar' className='bg-white border-primary text-primary w-fit mt-6 flex mx-auto' />
                            <Button onClick={() => (setOpenConfirmation(false), setOpen(true))} content='Cancelar' className='bg-white border-red text-red w-fit mt-6 flex mx-auto' />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

    return (auth.isAuthenticated ?
        <>
            <Helmet>
                <meta property="og:image" content={`${process.env.REACT_APP_DOMAIN}/Thumbnail.png`} />
            </Helmet>
            <Modal open={open} setOpen={setOpen} onClose={() => (
                setStudent((prev) => ({
                    firstName: '',
                    lastName: '',
                    username: '',
                    password: '',
                    govId: '',
                    guardian: prev.guardian,
                })),
                setError(''),
                setOpen(false)
            )} content={content} />
            <Modal containerClassName='max-w-xl' open={openConfirmation} setOpen={setOpenConfirmation} content={confirmDelete} onClose={() => (setOpenConfirmation(false), setOpen(true))} />
            <img className='hidden sm:block z-0 absolute left-[20%] top-[20%]' src={VectorIcon} alt='Vector' />
            <img className='hidden sm:block z-0 absolute right-[25%] top-[10%]' src={SphereIcon} alt='Sphere' />
            <img className='hidden sm:block z-0 absolute right-[15%] bottom-[5%]' src={AxisIcon} alt='Axis' />
            <img className='hidden sm:block z-0 absolute left-[10%] bottom-[5%]' src={LightbulbIcon} alt='Lightbulb' />
            <div className='bg-gray min-h-screen'>
                <>
                    <div className='flex justify-center mx-auto pt-10'>
                        <img src={PiensaLogo} alt='Piensa Logo' />
                    </div>
                    <p className='text-primary text-3xl font-bold text-center mt-4'>Añadí a los estudiantes</p>
                    <p className='text-primary text-lg font-bold text-center mt-4'>Añadí o selecciona los estudiantes que quieres inscribir</p>
                    <div className='mt-8 px-4 sm:px-0 sm:w-1/2 mx-auto grid grid-cols-1 sm:grid-cols-2 gap-4'>
                        {students.map((element, idx) => (
                            <div onClick={() => { selected === element.id ? setSelected(-1) : setSelected(element.id) }} className={classNames(selected === element.id ? 'bg-[#F8FEFF]' : 'bg-white', 'grid relative select-none cursor-pointer grid-cols-3 gap-8 rounded-3xl shadow-lg py-10 px-3 z-10')} key={element.id}>
                                <div className='ml-8 col-span-2'>
                                    <p className='font-semibold text-2xl'>{element.firstName + ' ' + element.lastName}</p>
                                    <div className='flex mt-2'>
                                        <p className='text-primary font-semibold'>Documento: </p>
                                        <p className='ml-2'>{element.govId}</p>
                                    </div>
                                </div>
                                <div onClick={(event) => (setStudent(element), setOldEmail(element.email), setOpen(true), event.stopPropagation())} className='col-span-1 text-center py-4 h-fit justify-center flex'>
                                    <p className='font-semibold text-primary'>Editar</p>
                                </div>
                                {selected === element.id ? <img src={Check} alt='Check' className='right-4 top-4 absolute' /> : null}
                            </div>
                        ))}
                    </div>
                    {students.length === 0 ? <p className='text-center mx-auto'>No tiene registrado ningún estudiante</p> : null}
                    <div className='flex w-full mt-4'>
                        <Button onClick={() => setOpen(true)} content={<div className='flex gap-4 whitespace-nowrap'><img src={Plus} alt='Plus' /><p>Añadir estudiante</p></div>} className='bg-white border-primary text-primary w-fit mt-6 flex mx-auto' />
                    </div>
                    <div className='flex w-full py-4'>
                        <Link to={selected !== -1 ? '/facturacion' : '/seleccion'} className={classNames(selected !== -1 ? 'bg-red border-red' : 'bg-[#D9D9D9] border-[#D9D9D9]', 'text-white w-1/3 mt-6 flex mx-auto py-2 px-7 justify-center align-middle border rounded-md shadow-sm')} >Siguiente</Link>
                    </div>
                </>
            </div>
        </>
        :
        <>
            <Helmet>
                <meta property="og:image" content={`${process.env.REACT_APP_DOMAIN}/Thumbnail.png`} />
            </Helmet>
            <div className='bg-white min-h-screen flex justify-center'>
                <div className="w-10 h-10 mx-5 border-b-2 my-auto border-primary rounded-full animate-spin" />
            </div>
        </>
    )
}
