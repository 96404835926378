
import ArcIcon from '../assets/ArcIcon.svg';
import Sidebar from '../components/sidebar'
import { useState, useEffect } from 'react';
import { useAuth } from '../context/authContext';
import api from '../services/api';
import { Bars3Icon } from '@heroicons/react/24/outline';
import { Helmet } from 'react-helmet';

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export default function Pagos() {

    const [payments, setPayments] = useState([]);
    const auth = useAuth();
    const [sidebarOpen, setSidebarOpen] = useState(false)

    useEffect(() => {
        if (auth.isAuthenticated) {
            api.payments.getPayments().then((response) => setPayments(response))
        }
    }, [auth])

    const isoToPy = (dateStr) => {
        if (dateStr) {
            const split = dateStr.split('-');
            return `${split[2]}/${split[1]}/${split[0]}`;
        }
    };

    return (auth.isAuthenticated ?
        <>
            <Helmet>
                <meta property="og:image" content={`${process.env.REACT_APP_DOMAIN}/Thumbnail.png`} />
            </Helmet>
            <img className='hidden sm:block absolute right-[20%] top-[25%]' src={ArcIcon} alt='Arc' />
            <div className='bg-white min-h-screen flex flex-col sm:flex-row'>
                <Sidebar selected={1} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 h-fit sm:pt-3 bg-white-off">
                    <button
                        type="button"
                        className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                        onClick={() => setSidebarOpen(true)}
                    >
                        <span className="sr-only">Open sidebar</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                <div className='flex flex-col w-full'>
                    <p className='text-[#1D6877] text-3xl font-bold mt-10 sm:mt-24 ml-6'>Historial de pagos</p>
                    <div className='bg-white-off bg-opacity-10 rounded-lg py-10 px-4 flex flex-col sm:w-[95%] h-full my-6'>
                        <div className='grid grid-cols-1 gap-6 w-full'>
                            {payments.map((element) => (
                                <div className='rounded-lg shadow-lg bg-white px-10 py-10' key={element.name}>
                                    <p className='font-bold text-2xl'>{element.dueDate ? `Cuota N° ${element.installmentNo} (${isoToPy(element.dueDate)})` : 'Matricula'}</p>
                                    <div className='my-6 border border-[#ECECEC]' />
                                    <div className='flex'><p className='text-primary font-medium text-lg sm:text-2xl'>Curso:</p><p className='text-lg sm:text-2xl ml-2'> {element.course}</p></div>
                                    <div className='flex mt-4 sm:mt-0'><p className='text-primary font-medium text-lg sm:text-2xl'>Estudiante:</p><p className='text-lg sm:text-2xl ml-2'> {element.student}</p></div>
                                    <div className='flex flex-col sm:flex-row justify-between mt-8'>
                                        <div className={classNames(element.status === 'Programado' ? 'bg-[#E92D2D] text-[#951E1E]' : 'bg-[#2DE956] text-[#1E9539]', 'font-bold bg-opacity-50 w-fit rounded-full px-7 py-2')}>
                                            {element.status}
                                        </div>
                                        <p className='text-[#F02929] text-lg sm:text-2xl font-bold mt-4 sm:mt-0'>Gs. {numberWithCommas(element.amount.substring(0, element.amount.indexOf('.')))}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div >
        </>
        :
        <>
            <Helmet>
                <meta property="og:image" content={`${process.env.REACT_APP_DOMAIN}/Thumbnail.png`} />
            </Helmet>
            <div className='bg-white min-h-screen flex justify-center'>
                <div className="w-10 h-10 mx-5 border-b-2 my-auto border-primary rounded-full animate-spin" />
            </div>
        </>
    )
}
