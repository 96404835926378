import axios from "axios";

const errorCodes = {
    badRequest: "badRequest",
    unauthorized: "unauthorized",
    forbidden: "forbidden",
    notFound: "notFound",
    serverError: "serverError",
    unexpected: "unexpected",
    invalidCredentials: "invalidCredentials",
};

class API {
    constructor(collection) {
        this.collectionUrl = `${process.env.REACT_APP_API_BASE}/${collection}`;
    }

    _handleError(error) {
        if (error.response?.status === 400)
            return Promise.reject({
                code: errorCodes.badRequest,
                data: error.response?.data,
            });
        if (error.response?.status === 401)
            return Promise.reject({
                code: errorCodes.unauthorized,
                data: error.response?.data,
            });
        if (error.response?.status === 403)
            return Promise.reject({
                code: errorCodes.forbidden,
                data: error.response?.data,
            });
        if (error.response?.status === 404)
            return Promise.reject({
                code: errorCodes.notFound,
                data: error.response?.data,
            });
        if (500 <= error.response?.status <= 599)
            return Promise.reject({
                code: errorCodes.serverError,
                data: error.response?.data,
            });
        return Promise.reject({
            code: errorCodes.unexpected,
            data: error.response?.data,
        });
    }

    async getMulti(
        ordering = null,
        search = null,
        limit = 100,
        offset = 0,
        extra = {}
    ) {
        const params = { limit, offset, ...extra };
        if (ordering) params.ordering = ordering;
        if (search && search.length > 0) params.search = search;
        try {
            const response = await axios.get(`${this.collectionUrl}/`, { params });
            return Promise.resolve(response.data);
        } catch (error) {
            return this._handleError(error);
        }
    }

    async getOne(id) {
        try {
            const response = await axios.get(`${this.collectionUrl}/${id}/`);
            return Promise.resolve(response.data);
        } catch (e) {
            return this._handleError(e);
        }
    }

    async create(data) {
        try {
            const response = await axios.post(`${this.collectionUrl}/`, data);
            return Promise.resolve(response.data);
        } catch (e) {
            return this._handleError(e);
        }
    }

    async update(id, data, partial = false) {
        try {
            let response;
            if (partial)
                response = await axios.patch(`${this.collectionUrl}/${id}/`, data);
            else response = await axios.put(`${this.collectionUrl}/${id}/`, data);
            return Promise.resolve(response.data);
        } catch (e) {
            return this._handleError(e);
        }
    }

    async delete(id) {
        try {
            return await axios.delete(`${this.collectionUrl}/${id}/`);
        } catch (e) {
            return this._handleError(e);
        }
    }
}

class MoodleAPI extends API {
    async getMe() {
        try {
            const response = await axios.get(`${this.collectionUrl}/me/`);
            return Promise.resolve(response.data);
        } catch (e) {
            return Promise.reject(errorCodes.invalidCredentials);
        }
    }

    async getToken(email, password) {
        try {
            const response = await axios.post(`${this.collectionUrl}/token/`, {
                email,
                password,
            });
            return Promise.resolve(response.data);
        } catch (e) {
            return Promise.reject(errorCodes.invalidCredentials);
        }
    }

    async getAllMoodle() {
        try {
            const response = await axios.get(`${this.collectionUrl}/moodle/`);
            return Promise.resolve(response.data);
        } catch (e) {
            return this._handleError(e);
        }
    }

    async getOneMoodle(id) {
        try {
            const response = await axios.get(`${this.collectionUrl}/moodle/${id}/`);
            return Promise.resolve(response.data);
        } catch (e) {
            return this._handleError(e);
        }
    }

    async createMoodle(data) {
        try {
            const response = await axios.post(`${this.collectionUrl}/moodle/`, data);
            return Promise.resolve(response.data);
        } catch (e) {
            return this._handleError(e);
        }
    }

    async updateMoodle(id, data, partial = true) {
        try {
            let response;
            if (partial)
                response = await axios.patch(`${this.collectionUrl}/moodle/${id}/`, data);
            else response = await axios.put(`${this.collectionUrl}/moodle/${id}/`, data);
            return Promise.resolve(response.data);
        } catch (e) {
            return this._handleError(e);
        }
    }

    async deleteMoodle(id) {
        try {
            return await axios.delete(`${this.collectionUrl}/moodle/${id}/`);
        } catch (e) {
            return this._handleError(e);
        }
    }

    async getCourses() {
        try {
            const response = await axios.get(`${this.collectionUrl}/my-courses/`);
            return Promise.resolve(response.data);
        } catch (e) {
            return this._handleError(e);
        }
    }

}

class PasswordAPI extends API {
    async sendEmail(email) {
        try {
            const response = await axios.post(`${this.collectionUrl}/`, { email: email });
            return Promise.resolve(response.data)
        } catch (e) {
            return this._handleError(e)
        }
    }

    async modifyPassword(uuid64, token, password, confirmPassword) {
        try {
            const response = await axios.post(`${this.collectionUrl}/${uuid64}/${token}/`, { password: password, confirmPassword: confirmPassword });
            return Promise.resolve(response.data)
        } catch (e) {
            return this._handleError(e)
        }
    }
}

class PaymentsAPI extends API {

    async confirmCard() {
        try {
            const response = await axios.post(`${this.collectionUrl}/confirm/`, {});
            return Promise.resolve(response.data)
        } catch (e) {
            return this._handleError(e)
        }
    }

    async getPayments() {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE}/payments/my-payments/`);
            return Promise.resolve(response.data)
        } catch (e) {
            return this._handleError(e)
        }
    }
}

class CoursesAPI extends API {
    async unsuscribe(idCourse, idStudent) {
        try {
            const response = await axios.post(`${this.collectionUrl}/unsuscribe/`, { course: idCourse, student: idStudent });
            return Promise.resolve(response.data)
        } catch (e) {
            return this._handleError(e)
        }
    }
}

const api = {
    users: new MoodleAPI("users"),
    courses: new CoursesAPI('courses'),
    reset_password: new PasswordAPI('reset-password'),
    enrollments: new API('enrollments'),
    payments: new PaymentsAPI('payments/cards'),
};

export default api;
export { errorCodes, API };
