import { useEffect, useState } from 'react'
import { useAuth } from '../context/authContext';
import { useNavigate, Link, useParams } from 'react-router-dom';
import api from '../services/api';
import { Helmet } from 'react-helmet';

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export default function Producto() {

    const auth = useAuth();
    const navigate = useNavigate();
    const { productId } = useParams();
    const [product, setProduct] = useState(undefined)

    useEffect(() => {
        if (productId) {
            api.courses.getOne(productId).then((response) => setProduct(response))
            sessionStorage.setItem('productoId', productId)
        }
    }, [productId, auth])

    return (
        <>
            <Helmet>
                <meta property="og:image" content={`${process.env.REACT_APP_DOMAIN}/Thumbnail.png`} />
            </Helmet>
            <div className='bg-white min-h-screen'>
                <div className='bg-gray h-full sm:mt-16 py-8 sm:py-16 px-10 flex flex-col'>
                    <div className='grid grid-cols-1 sm:grid-cols-2'>
                        {product?.image && <img className='sm:w-2/3 mx-auto' src={product.image} alt='Product' width={500} height={500} />}
                        <div className='flex flex-col mx-auto mt-6 sm:mt-0'>
                            <p className='text-primary font-bold text-3xl sm:text-5xl'>{product?.name}</p>
                            <p className='mt-4 sm:w-3/4 whitespace-break-spaces text-xl text-[#6B7280]'>{product?.description}</p>
                            {product?.enrollmentCost && <div className='flex mt-4'>
                                <p className='text-xl text-primary font-bold'>Matricula:</p>
                                <p className='ml-2  text-xl font-medium'>{product?.enrollmentCost ? numberWithCommas(product?.enrollmentCost.substring(0, product?.enrollmentCost.indexOf('.'))) : null} Gs.</p>
                            </div>}
                            <div className='flex mt-2'>
                                <p className='text-xl text-primary font-bold'>Cuotas:</p>
                                <p className='ml-2  text-xl font-medium'>{product?.installmentCost ? numberWithCommas(product?.installmentCost.substring(0, product?.installmentCost.indexOf('.'))) : null} Gs.</p>
                            </div>
                            <div className='flex mt-2'>
                                <p className='text-xl text-primary font-bold'>Cantidad de cuotas:</p>
                                <p className='ml-2  text-xl font-medium'>{product?.installmentsNumber}</p>
                            </div>
                        </div>
                    </div>
                    <div className='hidden sm:flex flex-row mx-auto mt-10'>
                        <p className='text-xl font-bold text-red whitespace-nowrap'>Inicia sesión</p>
                        <p className='ml-2 text-xl font-bold'>o</p>
                        <p className='ml-2 text-xl font-bold text-primary'>registrate</p>
                        <p className='ml-2 text-xl font-bold'>para proceder a la compra</p>
                    </div>
                    <div className='block sm:hidden mt-6'>
                        <div className='flex flex-row mx-auto'>
                            <p className='text-xl font-bold text-red whitespace-nowrap'>Inicia sesión</p>
                            <p className='ml-2 text-xl font-bold'>o</p>
                            <p className='ml-2 text-xl font-bold text-primary'>registrate</p>
                        </div>
                        <p className='text-xl font-bold'>para proceder a la compra</p>
                    </div>
                    <div className='flex w-full sm:justify-center gap-6 sm:mx-auto mt-6 sm:mt-10'>
                        <Link to='/login' className='bg-red bg-opacity-50 text-white sm:mt-6 flex py-2 px-4 sm:px-7 w-fit justify-center align-middle border rounded-md shadow-sm'>Iniciar Sesión</Link>
                        <Link to='/registrar' className='bg-primary border-primary text-white sm:mt-6 flex py-2 px-4 sm:px-7 w-fit justify-center align-middle border rounded-md shadow-sm'>Registrarse</Link>
                    </div>
                </div>
            </div>
        </>
    )
}
