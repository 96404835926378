import Button from '../components/button'
import ArcIcon from '../assets/ArcIcon.svg';
import TextInput from '../components/textInput'
import Sidebar from '../components/sidebar'
import Trash from "../assets/icons/Trash.svg"
import { useState, useEffect } from 'react';
import AddCardPopup from '../components/addCardPopup'
import api from '../services/api';
import { useSearchParams } from 'react-router-dom'
import { useAuth } from '../context/authContext'
import { Bars3Icon } from '@heroicons/react/24/outline';
import { Dialog } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import Modal from '../components/modal'
import { Helmet } from 'react-helmet';

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default function Tarjetas() {

    const [userData, setUserData] = useState(undefined)
    const [open, setOpen] = useState(false);
    const [cards, setCards] = useState([])
    const [selectedCard, setSelectedCard] = useState(undefined);
    const [processId, setProcessId] = useState(undefined);
    const [firstTime, setFirstTime] = useState(false);
    const [error, setError] = useState('');
    const searchParams = useSearchParams()
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [status, setStatus] = useState(undefined);
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [description, setDescription] = useState(undefined);
    const auth = useAuth();

    const updateCards = () => {
        if (auth.isAuthenticated) {
            api.payments.getMulti().then((response) => setCards(response))
        }
    }

    useEffect(() => {
        if (searchParams) {
            setStatus(searchParams[0].get('status') ? searchParams[0].get('status') : '')
            setDescription(searchParams[0].get('description') ? searchParams[0].get('description') : '')
        }
    }, [searchParams])

    useEffect(() => {
        if (auth.isAuthenticated) {
            try {
                if (status === 'add_new_card_success' && !firstTime) {
                    api.payments.confirmCard().then((response) => (updateCards()))
                    setFirstTime(true);
                }
            } catch (error) {
                setError(error);
            }
        }
    }, [auth, status, firstTime, updateCards])

    useEffect(() => {
        if (auth.isAuthenticated) {
            api.payments.getMulti().then((response) => setCards(response))
        }
    }, [auth])

    const deleteCard = (id) => {
        api.payments.delete(id).then((response) => (updateCards(), setOpenConfirmation(false)))
    }

    const getProcessId = () => {
        try {
            if (!processId) {
                api.payments.create({ redirectUrl: `${process.env.REACT_APP_DOMAIN}/tarjetas/`, })
                    .then((response) => {
                        setProcessId(response.processId);
                        setOpen(true);
                    });
            }
        } catch (error) {
            console.log(error);
        }
    };

    const confirmDelete = (
        <>
            <div>
                <div className="mt-3 text-center px-8">
                    <div className='flex justify-end'>
                        <XMarkIcon onClick={() => (setOpenConfirmation(false), setSelectedCard(undefined))} className='h-8 w-8 cursor-pointer' />
                    </div>
                    <Dialog.Title as="h3" className="text-lg mt-2 font-medium leading-6 text-gray-900">
                        <p>¿Está seguro que desea eliminar esta tarjeta?</p>
                    </Dialog.Title>
                    <div className="mt-2">
                        <div className='flex mx-auto w-fit gap-6'>
                            <Button onClick={() => deleteCard(selectedCard)} content='Aceptar' className='bg-white border-primary text-primary w-fit mt-6 flex mx-auto' />
                            <Button onClick={() => (setOpenConfirmation(false), setSelectedCard(undefined))} content='Cancelar' className='bg-white border-red text-red w-fit mt-6 flex mx-auto' />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

    return (auth.isAuthenticated ?
        <>
            <Helmet>
                <meta property="og:image" content={`${process.env.REACT_APP_DOMAIN}/Thumbnail.png`} />
            </Helmet>
            <Modal containerClassName='max-w-xl' open={openConfirmation} setOpen={setOpenConfirmation} content={confirmDelete} onClose={() => (setOpenConfirmation(false), setSelectedCard(undefined))} />
            {processId && <AddCardPopup open={open} setOpen={setOpen} onClose={() => (setOpen(false), setProcessId(undefined))} processId={processId} />}
            <img className='hidden sm:block absolute right-[20%] top-[25%]' src={ArcIcon} alt='Arc' />
            <div className='bg-white min-h-screen flex flex-col sm:flex-row'>
                <Sidebar selected={2} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <div className="sticky top-0 z-20 md:hidden pl-1 pt-1 sm:pl-3 h-fit sm:pt-3 bg-white-off">
                    <button
                        type="button"
                        className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                        onClick={() => setSidebarOpen(true)}
                    >
                        <span className="sr-only">Open sidebar</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                <div className='flex flex-col w-full'>
                    <p className='text-[#1D6877] text-3xl font-bold mt-10 sm:mt-24 ml-6'>Mis Tarjetas</p>
                    <div className='bg-white-off bg-opacity-10 rounded-lg py-10 px-4 flex flex-col sm:w-[95%] h-full my-6'>
                        <div className='grid grid-cols-1 sm:grid-cols-2 sm:gap-16 w-full'>
                            <div className='flex flex-col gap-4 sm:py-16'>
                                {cards.map((element) => (
                                    <div key={element.brand} className='flex justify-between bg-white shadow-lg rounded-3xl p-8'>
                                        <div className='flex flex-col sm:flex-row'>
                                            <p className='text-2xl font-bold'>{element.brand}</p>
                                            <p className='text-2xl font-medium sm:ml-4'>{element.number}</p>
                                        </div>
                                        <div onClick={() => (setSelectedCard(element.id), setOpenConfirmation(true))} className='cursor-pointer'>
                                            <img src={Trash} alt='Trash' />
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className='flex flex-col bg-white shadow-lg rounded-xl p-8 mt-4 sm:mt-0 sm:w-3/4 h-fit'>
                                <Button onClick={() => getProcessId()} className='bg-primary z-10 text-white font-semibold rounded-lg' content='Agregar tarjeta' />
                                <p className='text-red'>{error}</p>
                                {status === 'add_new_card_fail' && <p className='text-red mt-2'>{description}</p>}
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
        :
        <>
            <Helmet>
                <meta property="og:image" content={`${process.env.REACT_APP_DOMAIN}/Thumbnail.png`} />
            </Helmet>
            <div className='bg-white min-h-screen flex justify-center'>
                <div className="w-10 h-10 mx-5 border-b-2 my-auto border-primary rounded-full animate-spin" />
            </div>
        </>
    )
}
