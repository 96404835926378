
import Button from '../components/button'
import ArcIcon from '../assets/ArcIcon.svg';
import TextInput from '../components/textInput'
import Sidebar from '../components/sidebar'
import { useEffect, useState } from 'react'
import PasswordInput from '../components/passwordInput';
import api from '../services/api';
import { useAuth } from '../context/authContext';
import { Bars3Icon } from '@heroicons/react/24/outline';
import { Helmet } from 'react-helmet';

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default function Perfil() {

    const auth = useAuth();
    const [user, setUser] = useState(undefined)
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState('')
    const handleChange = (field, value) => setUser((prev) => ({ ...prev, [field]: value }));
    const [sidebarOpen, setSidebarOpen] = useState(false)

    useEffect(() => {
        if (auth.isAuthenticated) {
            api.users.getMe().then((response) => setUser(response))
        }
    }, [auth])

    const handleSubmit = async () => {
        setSuccess(false)
        setLoading(true)
        setError('')
        delete user.ruc;
        delete user.documentType;
        delete user.govId;
        delete user.phone;
        delete user.city;
        delete user.address;
        delete user.country;
        delete user.socialReason;
        api.users.update(user.id, user)
            .then((response) => {
                setSuccess(true)
                setLoading(false)
            })
            .catch((error) => (setError(error.data), setLoading(false)))
    }

    return (auth.isAuthenticated ?
        <>
            <Helmet>
                <meta property="og:image" content={`${process.env.REACT_APP_DOMAIN}/Thumbnail.png`} />
            </Helmet>
            <img className='absolute right-[20%] top-[25%]' src={ArcIcon} alt='Arc' />
            <div className='bg-white min-h-screen flex flex-col sm:flex-row'>
                <Sidebar selected={3} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <div className="sticky top-0 z-20 md:hidden pl-1 pt-1 sm:pl-3 h-fit sm:pt-3 bg-white-off">
                    <button
                        type="button"
                        className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                        onClick={() => setSidebarOpen(true)}
                    >
                        <span className="sr-only">Open sidebar</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                <div className='flex flex-col w-full'>
                    <p className='text-[#1D6877] text-3xl font-bold mt-10 sm:mt-24 ml-6'>Datos del perfil</p>
                    <div className='bg-white-off bg-opacity-10 rounded-lg py-4 sm:py-10 px-8 flex flex-col sm:w-[95%] h-full my-6'>
                        <div className='grid grid-cols-1 sm:grid-cols-2 gap-10 sm:gap-20 py-6 w-full'>
                            <div className='flex flex-col space-y-6'>
                                <p className='text-primary font-bold text-xl'>Datos personales</p>
                                <div>
                                    <p className='text-[#6B7280] font-semibold'>Nombre:</p>
                                    <TextInput error={error?.firstName} value={user?.firstName} handleChange={handleChange} name='firstName' className='border-primary rounded-md' placeholder='Escribí tu nombre' />
                                </div>
                                <div>
                                    <p className='text-[#6B7280] font-semibold'>Apellido:</p>
                                    <TextInput error={error?.lastName} value={user?.lastName} handleChange={handleChange} name='lastName' className='border-primary rounded-md' placeholder='Escribí tus apellidos' />
                                </div>
                            </div>
                            <div className='flex flex-col space-y-6'>
                                <p className='text-primary font-bold text-xl'>Datos de autenticación</p>
                                <div>
                                    <p className='text-[#6B7280] font-semibold'>Email:</p>
                                    <TextInput error={error?.email} value={user?.email} handleChange={handleChange} name='email' className='border-primary rounded-md' placeholder='Ingresa tu correo' />
                                </div>
                                <p className='text-primary font-bold text-xl'>Cambiar contraseña</p>
                                <div>

                                    <p className='text-[#6B7280] font-semibold'>Contraseña actual:</p>
                                    <PasswordInput error={error?.oldPassword} handleChange={handleChange} name='oldPassword' className='border-primary rounded-md' />
                                </div>
                                <div>

                                    <p className='text-[#6B7280] font-semibold'>Nueva contraseña:</p>
                                    <PasswordInput error={error?.newPassword} handleChange={handleChange} name='newPassword' className='border-primary rounded-md' />
                                </div>
                                <Button onClick={() => handleSubmit()} className='bg-primary text-white w-full sm:w-64 self-end rounded-lg' content={loading ? <div className="w-5 h-5 mx-5 border-b-2 border-white rounded-full animate-spin" /> : 'Guardar cambios'} />
                                {success ? <p className='text-end text-primary'>Cambios guardados exitosamente.</p> : null}
                                {error ? <p>{ }</p> : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
        :
        <>
            <Helmet>
                <meta property="og:image" content={`${process.env.REACT_APP_DOMAIN}/Thumbnail.png`} />
            </Helmet>
            <div className='bg-white min-h-screen flex justify-center'>
                <div className="w-10 h-10 mx-5 border-b-2 my-auto border-primary rounded-full animate-spin" />
            </div>
        </>
    )
}
