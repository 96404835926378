import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function PasswordInput({ name, placeholder, value, containerClassName, className, handleChange, error, eyeClassname }) {

    const [visible, setVisible] = useState(false)

    return (
        <div className={classNames(containerClassName ? containerClassName : '')}>
            <div className='flex'>
                <input
                    type={visible ? 'text' : 'password'}
                    onChange={(event) => handleChange(name, event.target.value)}
                    name={name}
                    id={name}
                    value={value}
                    className={classNames(error ? 'border-2 border-red border-r-0' : ' border-2 border-[#D9D9D9] border-r-0', className, "h-10 peer outline-none rounded-r-none placeholder:text-[#757575] bg-white placeholder:font-semibold placeholder:text-xs focus:border-blue-500 focus:ring-blue-500 placeholder:text-opacity-50 block w-full px-4 shadow-sm sm:text-sm")}
                    placeholder={placeholder}
                />
                <div onClick={() => setVisible(!visible)} className={classNames(eyeClassname ? eyeClassname : '', 'py-1 bg-white cursor-pointer border-l-0 border-2 border-[#D9D9D9] h-10 peer-focus:border-blue-500 peer:ring-blue-500 rounded-br-md pr-2')}>
                    {!visible ? <EyeIcon color='#9CA3AF' width={30} height={30} /> :
                        <EyeSlashIcon color='#9CA3AF' width={30} height={30} />
                    }
                </div>
            </div>
            {error ? <p className="text-red text-sm">{error}</p> : null}
        </div>
    )
}