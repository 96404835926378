function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function DateInput({ name, placeholder, value, containerClassName, className, handleChange, error }) {
    return (
        <div className={classNames(containerClassName ? containerClassName : '')}>
            <input
                type="date"
                onChange={(event) => handleChange(name, event.target.value)}
                name={name}
                id={name}
                value={value}
                className={classNames(error ? 'border-2 border-red' : ' border-2 border-[#DBDBDB]', className, "h-10 placeholder:text-[#757575] bg-white placeholder:font-semibold placeholder:text-xs block w-full px-4 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm")}
                placeholder={placeholder}
            />
            {error ? <p className="text-red text-sm">{error}</p> : null}
        </div>
    )
}