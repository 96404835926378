
import ArcIcon from '../assets/ArcIcon.svg';
import Sidebar from '../components/sidebar'
import Course from '../assets/Course.png';
import UserOrange from '../assets/icons/UserOrange.svg';
import { useState, useEffect } from 'react';
import { useAuth } from '../context/authContext';
import api from '../services/api';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { Helmet } from 'react-helmet';
import { Dialog } from '@headlessui/react'
import Modal from '../components/modal';
import Button from '../components/button';

export default function Cursos() {

  const [courses, setCourses] = useState([]);
  const auth = useAuth();
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [selectedStudent, setSelectedStudent] = useState(undefined)
  const [selectedCourse, setSelectedCourse] = useState(undefined)
  const [errorMessage, setErrorMessage] = useState('')
  const [open, setOpen] = useState(false)
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    if (auth.isAuthenticated) {
      api.users.getCourses().then((response) => setCourses(response))
    }
  }, [auth])

  useEffect(() => {
    if (success) {
      api.users.getCourses().then((response) => setCourses(response))
    }
  }, [success])

  const unsuscribe = async (idCourse, idStudent) => {
    setLoading(true)
    api.courses.unsuscribe(idCourse, idStudent).then((response) => {
      setOpen(false);
      setSuccess(true);
      setLoading(false);
    }).catch((error) => (setErrorMessage(error), setLoading(error)))
  }

  const content = (
    <>
      <div>
        <div className="mt-3 text-center px-8">
          <div className='flex justify-end'>
            <XMarkIcon onClick={loading ? null : () => (
              setOpen(false),
              setSelectedStudent(undefined),
              setSelectedCourse(undefined))} className='h-8 w-8 cursor-pointer' />
          </div>
          <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
          </Dialog.Title>
          <div className="mt-2">
            <div className='mx-auto text-start font-bold text-red'>
              <p>¿Está seguro/a que desea cancelar la suscripción de {selectedStudent?.fullName} al curso {selectedCourse?.name}? </p>
              <ul className='list-disc ml-4 gap-y-3 mt-4'>
                <li className='font-normal'>Esta operación no se puede deshacer</li>
                <li className='font-normal'>Al momento de cancelar la suscripción ya no se le realizará ningún cobro de esta suscripción</li>
                <li className='font-normal'>Seguirá teniendo acceso al curso suscripto hasta que finalice el periodo ya abonado en su última cuota: </li>
              </ul>
            </div>
            <div className='flex gap-3 justify-center'>
              <Button onClick={loading ? null : () => (setOpen(false), setSelectedStudent(undefined), setSelectedCourse(undefined))} content='Volver atrás' className='bg-[#F15015] bg-opacity-[14%] border-[#F15015] border-opacity-[14%] text-black font-medium w-fit mt-6 flex' />
              <Button onClick={loading ? null : () => unsuscribe(selectedCourse.id, selectedStudent.id)} content={loading ? <div className="w-5 h-5 mx-5 border-b-2 border-white rounded-full animate-spin" /> : 'Sí, deseo cancelar mi suscripción'} className='bg-red border-red text-white font-semibold w-fit mt-6 flex' />
            </div>
          </div>
        </div>
      </div>
    </>
  )

  const unsubSuccess = (
    <>
      <div>
        <div className="mt-3 text-center px-8">
          <div className='flex justify-end'>
            <XMarkIcon onClick={() => { setSuccess(false) }} className='h-8 w-8 cursor-pointer' />
          </div>
          <Dialog.Title as="h3" className="text-lg mt-2 font-bold leading-6 text-red">
            <p>Ha cancelado el servicio exitosamente</p>
          </Dialog.Title>
          <div className="mt-2">
            <div className='flex mx-auto w-fit gap-6'>
              <Button onClick={() => setSuccess(false)} content='Aceptar' className='bg-red border-red text-white font-semibold w-fit mt-6 mx-auto py-2 px-7 justify-center align-middle inline-flex border rounded-md shadow-sm' />
            </div>
          </div>
        </div>
      </div>
    </>
  )

  return (auth.isAuthenticated ?
    <>
      <Helmet>
        <meta property="og:image" content={`${process.env.REACT_APP_DOMAIN}/Thumbnail.png`} />
      </Helmet>
      <Modal open={open} setOpen={setOpen} onClose={() => (setSelectedStudent(undefined), setSelectedCourse(undefined), setOpen(false))} content={content} />
      <Modal containerClassName='max-w-xl' open={success} setOpen={setSuccess} onClose={() => setSuccess(false)} content={unsubSuccess} />
      <img className='hidden sm:block absolute right-[20%] top-[25%]' src={ArcIcon} alt='Arc' />
      <div className='bg-white min-h-screen flex flex-col sm:flex-row'>
        <Sidebar selected={0} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 h-fit sm:pt-3 bg-white-off">
          <button
            type="button"
            className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className='flex flex-col w-full'>
          <p className='text-[#1D6877] text-3xl font-bold mt-6 sm:mt-24 ml-6'>Mis Cursos</p>
          <div className='bg-white-off bg-opacity-10 rounded-lg py-10 px-4 flex flex-col sm:w-[95%] my-6'>
            <div className='flex flex-col space-y-6 mt-10 w-full'>
              {courses.map((element) => (
                <div key={element.name}>
                  <div className='rounded-t-full bg-white sm:w-1/3 py-3 justify-start text-center'>
                    <a href='https://online.piensa.la/' target="_blank" rel="noreferrer noopener" className='text-primary font-semibold underline'>{element.course.name}</a>
                  </div>
                  <div className='flex flex-col sm:flex-row w-full bg-white rounded-r-lg rounded-bl-lg'>
                    <div className='sm:w-1/4 h-full flex rounded-lg py-6 sm:py-12 overflow-clip'>
                      <a href='https://online.piensa.la/' target="_blank" rel="noreferrer noopener">
                        <img className='w-3/4 self-center mx-auto' src={process.env.REACT_APP_API_BASE + element.course.image} width={400} height={400} alt='Course' />
                      </a>
                    </div>
                    <div className='flex flex-col space-y-4 xl:w-1/2 text-center sm:text-left px-4 sm:px-0 sm:py-16 '>
                      <p className='text-primary font-semibold'>{element.course.subtitle}</p>
                      <p className='text-sm'>{element.course.description}</p>
                    </div>
                    <div className='flex flex-col gap-2 py-4 px-6 sm:px-6 sm:py-20'>
                      {element.students.map((student, idx) => (
                        <div key={idx} className='py-2 px-5 flex justify-between gap-2 text-red text-opacity-50 bg-[#F15015] bg-opacity-[14%] rounded-full'><div className='flex gap-x-2'><img alt='User' width={24} height={24} src={UserOrange} /> {student.fullName}</div><p onClick={() => (setSelectedStudent(student), setSelectedCourse(element.course), setOpen(true))} className='underline my-auto cursor-pointer'>Cancelar</p></div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div >
    </>
    :
    <>
      <Helmet>
        <meta property="og:image" content={`${process.env.REACT_APP_DOMAIN}/Thumbnail.png`} />
      </Helmet>
      <div className='bg-white min-h-screen flex justify-center'>
        <div className="w-10 h-10 mx-5 border-b-2 my-auto border-primary rounded-full animate-spin" />
      </div>
    </>
  )
}
