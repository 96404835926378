function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function TextInput({ name, placeholder, value, containerClassName, className, handleChange, disabled, error }) {
    return (
        <div className={classNames(containerClassName ? containerClassName : '')}>
            <input
                type="text"
                onChange={(event) => handleChange(name, event.target.value)}
                name={name}
                id={name}
                value={value}
                disabled={disabled}
                className={classNames(disabled ? 'bg-[#D9D9D9]' : 'bg-white', error ? 'border-2 border-red' : ' border-2 border-[#DBDBDB]', className, "placeholder:text-[#757575] h-10 placeholder:font-semibold placeholder:text-xs placeholder:text-opacity-50 block w-full px-4 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm")}
                placeholder={placeholder}
            />
            {error ? <p className="text-red text-sm h-fit">{error}</p> : null}
        </div>
    )
}