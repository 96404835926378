import { ChevronDownIcon } from "@heroicons/react/24/outline"

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function SelectInput({ name, options, placeholder, containerClassName, className, handleChange, error, value }) {
    return (
        <div className={classNames(containerClassName ? containerClassName : '', 'h-10 flex')}>
            <select
                onChange={(event) => handleChange(name, event.target.value)}
                name={name}
                id={name}
                value={value}
                className={classNames(error ? 'border-2 border-red' : ' border-2 border-[#DBDBDB]', className, "placeholder:text-[#757575] bg-transparent appearance-none bg-white placeholder:font-semibold placeholder:text-xs block w-full h-full px-4 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm")}
                placeholder={placeholder}
            >
                {options}
            </select>
            <ChevronDownIcon className="w-5 h-5 self-center -ml-8 relative z-0" />
        </div>
    )

}