import { XMarkIcon } from "@heroicons/react/24/outline";
import { useEffect } from "react";
import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const testMode = process.env.REACT_APP_BANCARD_TEST_MODE || "true";
const bancardUrl =
    testMode.toLowerCase() === "true"
        ? "https://vpos.infonet.com.py:8888/checkout/javascript/dist/bancard-checkout-3.0.0.js"
        : "https://vpos.infonet.com.py/checkout/javascript/dist/bancard-checkout-3.0.0.js";


export default function Modal({ open, setOpen, onClose, containerClassName, processId }) {

    useEffect(() => {
        const script = document.createElement("script");
        script.src = bancardUrl;
        const runBancard = document.createElement("script");
        runBancard.innerHTML = `Bancard.Cards.createForm('bancardIframe', '${processId}');`;
        script.onload = () => {
            document.body.appendChild(runBancard);
        };
        document.body.appendChild(script);
    }, []);

    return (
        <>
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-30" onClose={onClose ? onClose : setOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>
                    <div className="fixed inset-0 z-30 overflow-y-auto">
                        <div className="flex min-h-full items-start justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className={classNames(containerClassName ? containerClassName : 'sm:max-w-5xl bg-white', "relative transform overflow-hidden rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:p-6")}>
                                    <div className='flex justify-end'>
                                        <XMarkIcon onClick={() => onClose()} className='h-8 w-8 cursor-pointer' />
                                    </div>
                                    <div style={{ height: "390px" }} id="bancardIframe"></div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>

    )
}