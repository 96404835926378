import Button from '../components/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import VectorIcon from '../assets/VectorIcon.svg';
import SphereIcon from '../assets/SphereIcon.svg';
import AxisIcon from '../assets/AxisIcon.png';
import LightbulbIcon from '../assets/LightbulbIcon.png';
import Info from '../assets/icons/Info.svg';
import { useEffect, useState } from 'react'
import SelectInput from '../components/selectInput'
import Modal from '../components/modal'
import { Dialog } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import AddCardPopup from '../components/addCardPopup'
import { useSearchParams } from 'react-router-dom'
import Plus from "../assets/icons/Plus.svg"
import { Link } from "react-router-dom";
import api from '../services/api';
import { useAuth } from '../context/authContext';
import { Helmet } from 'react-helmet';

function numberWithCommas(x) {
    if (x)
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Resumen() {

    const auth = useAuth();
    const [userData, setUserData] = useState(undefined);
    const [error, setError] = useState('');
    const [cards, setCards] = useState([]);
    const [open, setOpen] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [success, setSuccess] = useState(false);
    const [students, setStudents] = useState([]);
    const [course, setCourse] = useState(undefined);
    const [card, setCard] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [storage, setStorage] = useState(undefined);
    const [productId, setProductId] = useState(undefined);
    const [processId, setProcessId] = useState(undefined);
    const [firstTime, setFirstTime] = useState(false);
    const searchParams = useSearchParams()
    const [status, setStatus] = useState(undefined);
    const [description, setDescription] = useState(undefined);
    const handleChange = (field, value) => {
        setCard(cards[value])
    };

    useEffect(() => {
        if (auth.isAuthenticated) {
            api.users.getMe().then((response) => setUserData(response))
            api.users.getAllMoodle().then((response) => setStudents(response))
            api.payments.getMulti().then((response) => setCards(response))
        }
    }, [auth])

    useEffect(() => {
        if (cards.length > 0) {
            setCard(cards[0])
        }
    }, [cards])

    useEffect(() => {
        if (searchParams) {
            setStatus(searchParams[0].get('status') ? searchParams[0].get('status') : '')
            setDescription(searchParams[0].get('description') ? searchParams[0].get('description') : '')
        }
    }, [searchParams])

    useEffect(() => {
        if (productId)
            api.courses.getOne(productId).then((response) => setCourse(response))
    }, [productId])

    useEffect(() => {
        setStorage(sessionStorage.getItem('selected').split(',').map((element) => parseInt(element)));
        setProductId(sessionStorage.getItem('productoId'));
    }, [])

    const handleSubmit = () => {
        setLoading(true)
        api.enrollments.create({ course: productId, students: storage, payment_method: card.id })
            .then((response) => {
                setLoading(false);
                setSuccess(true);
            })
            .catch((error) => {
                setLoading(false)
                setError(error.data)
            })
    }

    const updateCards = () => {
        if (auth.isAuthenticated) {
            api.payments.getMulti().then((response) => setCards(response))
        }
    }

    useEffect(() => {
        if (auth.isAuthenticated) {
            try {
                if (status === 'add_new_card_success' && !firstTime) {
                    api.payments.confirmCard().then((response) => (updateCards()))
                    setFirstTime(true);
                }
            } catch (error) {
                setError(error);
            }
        }
    }, [auth, status, firstTime, updateCards])

    const getProcessId = () => {
        try {
            api.payments.create({ redirectUrl: `${process.env.REACT_APP_DOMAIN}/resumen/`, })
                .then((response) => {
                    setProcessId(response.processId);
                    setOpen(true);
                });
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (processId)
            setShowModal(true);
    }, [processId])

    const paymentSuccess = (
        <>
            <div>
                <div className="mt-3 text-center px-8">
                    <Dialog.Title as="h3" className="text-lg mt-2 font-medium leading-6 text-gray-900">
                        <p>Su pago fue procesado exitosamente.</p>
                    </Dialog.Title>
                    <div className="mt-2">
                        <div className='flex mx-auto w-fit gap-6'>
                            <Link to='/cursos' className='bg-primary border-primary text-white w-fit mt-6 mx-auto py-2 px-7 justify-center align-middle inline-flex border rounded-md shadow-sm'>Ir al Panel de Administración</Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

    return (auth.isAuthenticated ?
        <>
            <Helmet>
                <meta property="og:image" content={`${process.env.REACT_APP_DOMAIN}/Thumbnail.png`} />
            </Helmet>
            <Modal containerClassName='max-w-xl' open={success} setOpen={setSuccess} content={paymentSuccess} />
            {processId && <AddCardPopup open={open} setOpen={setOpen} processId={processId} setShowModal={setShowModal} />}
            <img className='hidden sm:block z-0 absolute left-[20%] top-[20%]' src={VectorIcon} alt='Vector' />
            <img className='hidden sm:block z-0 absolute right-[25%] top-[10%]' src={SphereIcon} alt='Sphere' />
            <img className='hidden sm:block z-0 absolute right-[15%] bottom-[5%]' src={AxisIcon} alt='Axis' />
            <img className='hidden sm:block z-0 absolute left-[10%] bottom-[5%]' src={LightbulbIcon} alt='Lightbulb' />
            <div className='bg-white min-h-screen'>
                <p className='text-primary text-3xl sm:text-5xl font-bold text-center pt-10 sm:pt-16'>Lista de cursos</p>
                <p className='text-primary text-lg font-medium text-center mt-4'>Detalles de tu compra</p>
                <div className='bg-gray mt-4 py-8 sm:py-16 px-4 sm:px-8 flex flex-col justify-center'>
                    <div className='bg-white rounded-3xl w-full sm:w-3/4 mx-auto px-10 py-8 z-10 grid grid-cols-1 sm:grid-cols-2 justify-center'>
                        <div className='mx-auto'>
                            <p className='text-primary font-bold text-3xl'>{course?.name}</p>
                            <div className='mt-8 grid grid-cols-1 sm:grid-cols-2 gap-4'>
                                <div className='flex flex-col space-y-3'>
                                    <p className='text-primary font-bold text-2xl sm:whitespace-nowrap'>Datos de Facturación</p>
                                    <div className='flex flex-col sm:flex-row'>
                                        <p className='text-primary font-medium text-2xl'>Nombre:</p>
                                        <p className='font-medium text-2xl ml-0 sm:ml-2 sm:whitespace-nowrap'>{userData?.firstName + ' ' + userData?.lastName}</p>
                                    </div>
                                    {userData?.socialReason && <div className='flex flex-col sm:flex-row'>
                                        <p className='text-primary font-medium text-2xl whitespace-nowrap'>Razón Social:</p>
                                        <p className='font-medium text-2xl ml-0 sm:ml-2 sm:whitespace-nowrap'>{userData?.socialReason}</p>
                                    </div>}
                                    {userData?.ruc &&
                                        <div className='flex flex-col sm:flex-row'>
                                            <p className='text-primary font-medium text-2xl'>RUC:</p>
                                            <p className='font-medium text-2xl ml-0 sm:ml-2 whitespace-nowrap'>{userData?.ruc}</p>
                                        </div>}
                                    <Link to='/facturacion/editar' className='bg-white border-primary text-primary mt-6 flex py-2 px-4 w-fit justify-center align-middle border rounded-md shadow-sm'>
                                        Editar datos de facturación
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col mx-auto'>
                            <p className='text-primary font-bold text-3xl invisible'>{course?.name}</p>
                            <div className='mt-4 sm:mt-8'>
                                <p className='text-primary font-bold text-2xl'>Datos de Estudiante</p>
                                {students.filter((element) => storage.includes(element.id)).map((element) => (
                                    <div key={element.name} className='mt-4'>
                                        <div className='flex flex-col sm:flex-row'>
                                            <p className='text-primary font-medium text-2xl'>Estudiante:</p>
                                            <p className='font-medium text-2xl ml-0 sm:ml-2'>{element.firstName + ' ' + element.lastName}</p>
                                        </div>
                                        <div className='flex flex-col sm:flex-row mt-4 sm:mt-0'>
                                            <p className='text-primary font-medium text-2xl'>Documento:</p>
                                            <p className='font-medium text-2xl ml-0 sm:ml-2'>{element.govId}</p>
                                        </div>
                                    </div>
                                ))}
                                <div className='border my-4 sm:my-8' />
                                {course?.enrollmentCost ?
                                    <>
                                        <div className='flex justify-between'>
                                            <p className='text-primary font-medium text-2xl'>Matricula:</p>
                                            <p className='font-medium text-2xl ml-2'>{'Gs. ' + course ? numberWithCommas(course?.enrollmentCost.substring(0, course?.enrollmentCost.indexOf('.'))) : null}</p>
                                        </div>
                                        <div className='flex justify-between'>
                                            <p className='text-primary font-medium text-2xl'>Cuota mensual:</p>
                                            <p className='font-medium text-2xl ml-2'>{'Gs. ' + course ? numberWithCommas(course?.installmentCost.substring(0, course?.installmentCost.indexOf('.'))) : null}</p>
                                        </div>
                                        {course?.installmentsNumber && <div className='flex justify-between'>
                                            <p className='text-primary font-medium text-2xl'>Cantidad de cuotas:</p>
                                            <p className='font-medium text-2xl ml-2'>{'x' + course?.installmentsNumber}</p>
                                        </div>}
                                    </>
                                    :
                                    <>
                                        <div className='flex justify-between'>
                                            <p className='text-primary font-medium text-2xl'>Cuota mensual:</p>
                                            <p className='font-medium text-2xl ml-2'>{'Gs. ' + course ? numberWithCommas(course?.installmentCost.substring(0, course?.installmentCost.indexOf('.'))) : null}</p>
                                        </div>
                                        {course?.installmentsNumber && <div className='flex justify-between'>
                                            <p className='text-primary font-medium text-2xl'>Cantidad de cuotas:</p>
                                            <p className='font-medium text-2xl ml-2'>{'x' + course?.installmentsNumber}</p>
                                        </div>}
                                    </>
                                }
                                <div className='flex mt-4'>
                                    <img src={Info} alt='Info' />
                                    <p className='text-primary text-sm ml-2'>Pago mensual con renovación automática</p>
                                </div>
                                {cards.length > 0 ?
                                    <>
                                        <div className='flex gap-2'>
                                            <SelectInput name='card' handleChange={handleChange} containerClassName='mt-2 w-full' className='border-primary rounded-md' options={cards.map((element, idx) => (<option key={idx} name={element.brand} value={idx}>{element.brand}</option>))} />
                                            <Button onClick={() => getProcessId()} className='bg-white text-primary font-semibold rounded-lg w-fit mt-2' content={<img src={Plus} alt='Plus' />
                                            } />
                                        </div>
                                        {status === 'add_new_card_fail' && <p className='text-red mt-2'>{description}</p>}
                                    </>
                                    :
                                    <>
                                        <Button onClick={() => getProcessId()} className='bg-primary text-white font-semibold rounded-lg w-full mt-2' content='Agregar tarjeta' />
                                        {status === 'add_new_card_fail' && <p className='text-red mt-2'>{description}</p>}
                                    </>
                                }
                                <Button onClick={card ? () => handleSubmit() : null} content={loading ? <div className="w-5 h-5 mx-5 border-b-2 border-white rounded-full animate-spin" /> : 'Realizar pago'} className={classNames(card ? 'bg-primary border-primary' : 'bg-[#D9D9D9] border-[#D9D9D9]', 'text-white mt-6 flex py-2 px-4 w-full justify-center align-middle border rounded-md shadow-sm')} />
                                {error && <p className='text-red mt-2'>{error?.nonFieldErrors ? error.nonFieldErrors : error}</p>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
        :
        <>
            <Helmet>
                <meta property="og:image" content={`${process.env.REACT_APP_DOMAIN}/Thumbnail.png`} />
            </Helmet>
            <div className='bg-white min-h-screen flex justify-center'>
                <div className="w-10 h-10 mx-5 border-b-2 my-auto border-primary rounded-full animate-spin" />
            </div>
        </>
    )
}
