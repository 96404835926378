function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default function Button({ content, className, onClick }) {
    // primero revisa si la url es un link externo basado en si tiene o no puntos
    return (
        <button onClick={onClick} className={classNames(className, 'py-2 px-7 justify-center align-middle inline-flex border rounded-md shadow-sm')}>
            {content}
        </button>
    )
}
