import PiensaLogo from '../assets/PiensaLogo.svg';
import { Dialog, Transition } from '@headlessui/react'
import Folder from "../assets/icons/Folder.svg"
import Calendar from "../assets/icons/Calendar.svg"
import CreditCard from "../assets/icons/CreditCard.svg"
import User from "../assets/icons/User.svg"
import GradCap from "../assets/icons/GradCap.svg"
import Youtube from "../assets/icons/Youtube.svg"
import Instagram from "../assets/icons/Instagram.svg"
import Facebook from "../assets/icons/Facebook.svg"
import Help from "../assets/icons/Help.svg"
import Logout from "../assets/icons/Logout.svg"
import FolderOrange from '../assets/icons/FolderOrange.svg'
import CalendarOrange from '../assets/icons/CalendarOrange.svg'
import CreditCardOrange from '../assets/icons/CreditCardOrange.svg'
import GradCapOrange from '../assets/icons/GradCapOrange.svg'
import UserOrange from '../assets/icons/UserOrange.svg'
import { useState, Fragment } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../context/authContext';
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import Modal from './modal';

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

const tabs = [
    { name: 'Mis Cursos', icon: <img src={Folder} alt='Folder' />, iconActive: <img src={FolderOrange} alt='Folder' />, href: '/cursos' },
    { name: 'Pagos', icon: <img src={Calendar} alt='Calendar' />, iconActive: <img src={CalendarOrange} alt='Calendar' />, href: '/pagos' },
    { name: 'Mis Tarjetas', icon: <img src={CreditCard} alt='Credit' />, iconActive: <img src={CreditCardOrange} alt='Credit' />, href: '/tarjetas' },
    { name: 'Perfil', icon: <img src={User} alt='User' />, iconActive: <img src={UserOrange} alt='User' />, href: '/perfil' },
    { name: 'Estudiantes', icon: <img src={GradCap} alt='Grad' />, iconActive: <img src={GradCapOrange} alt='Grad' />, href: '/estudiantes' },
]

const social = [
    { name: 'YouTube', icon: <img src={Youtube} alt='Youtube' />, href: 'https://www.youtube.com/@piensa_latam/' },
    { name: 'Instagram', icon: <img src={Instagram} alt='Instagram' />, href: 'https://www.instagram.com/piensalatam/' },
    { name: 'Facebook', icon: <img src={Facebook} alt='Facebook' />, href: 'https://www.facebook.com/piensalatam/' },
]

export default function Sidebar({ selected, sidebarOpen, setSidebarOpen }) {
    // primero revisa si la url es un link externo basado en si tiene o no puntos
    const navigate = useNavigate();
    const [openLogout, setOpenLogout] = useState(false)
    const auth = useAuth();
    const handleLogout = () => {
        auth.signOut().then((response) => {
            navigate('/')
        })
    }

    const logout = (
        <>
            <div>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-yellow-100">
                    <ExclamationTriangleIcon className="h-6 w-6 text-yellow-800" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                        Cerrar sesión
                    </Dialog.Title>
                    <div className="mt-2">
                        <p className="text-sm text-gray-500">
                            ¿Estás seguro de que quieres salir?
                        </p>
                    </div>
                </div>
            </div >
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-800 sm:col-start-2 sm:text-sm"
                    onClick={async () => {
                        await auth.signOut();
                        navigate('/login');
                    }}
                >
                    Cerrar Sesión
                </button>
                <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => setOpenLogout(false)}
                >
                    Cancelar
                </button>
            </div>
        </>
    )

    return (
        <>
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog as="div" className="fixed inset-0 flex z-50 sm:hidden" onClose={setSidebarOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                    </Transition.Child>
                    <Transition.Child
                        as={Fragment}
                        enter="transition ease-in-out duration-300 transform"
                        enterFrom="-translate-x-full"
                        enterTo="translate-x-0"
                        leave="transition ease-in-out duration-300 transform"
                        leaveFrom="translate-x-0"
                        leaveTo="-translate-x-full"
                    >
                        <div className="relative flex-1 flex flex-col max-w-xs pl-2 w-full pt-5 bg-white">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-in-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in-out duration-300"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="absolute top-0 right-0 pt-2">
                                    <button
                                        type="button"
                                        className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                        onClick={() => setSidebarOpen(false)}
                                    >
                                        <span className="sr-only">Close sidebar</span>
                                        <XMarkIcon className="h-6 w-6 text-black" aria-hidden="true" />
                                    </button>
                                </div>
                            </Transition.Child>
                            <img alt='Logo' className="sm:mb-8 ml-4 sm:ml-8 w-32 h-32 sm:w-auto sm:h-auto" src={PiensaLogo} />
                            <div className='flex flex-col space-y-3 mb-2 sm:mb-0'>
                                {tabs.map((element, idx) => (
                                    <Link key={element.name} to={element.href} className="flex flex-row">
                                        <div className="w-6 h-6">{idx === selected ? element.iconActive : element.icon}</div>
                                        <p className={classNames(idx === selected ? '' : 'text-[#4B5563]', "ml-4 font-medium")}>{element.name}</p>
                                    </Link>
                                ))}
                            </div>
                            <p className="border-t pt-2 sm:pt-4 translate-y-2 pb-2 font-bold text-[#4B5563] border-[#4B5563]">Redes sociales</p>
                            <div className='flex flex-col space-y-3 mt-4 mb-2 sm:mb-0'>
                                {social.map((element, idx) => (
                                    <a href={element.href} target="_blank" rel="noreferrer noopener" key={element.name} className="flex flex-row">
                                        <div className="w-6 h-6">{element.icon}</div>
                                        <p className="ml-4 -mt-1 sm:mt-0 font-medium text-[#4B5563]">{element.name}</p>
                                    </a>
                                ))}
                            </div>
                            <p className="border-t sm:pt-4 pt-2 translate-y-2 pb-2 font-bold text-[#4B5563] border-[#4B5563]">Soporte</p>
                            <a href='https://wa.me/+595986366060' target="_blank" rel="noreferrer noopener" key='Solicitar Ayuda' className="flex flex-row mt-2">
                                <div className="w-6 h-6">{<img alt='Help' src={Help} />}</div>
                                <p className="ml-2 font-medium text-[#4B5563] -mt-0.5 sm:mt-0" >Solicitar Ayuda</p>
                            </a>
                            <div onClick={() => handleLogout()} className='cursor-pointer border-0 shadow-none text-red -translate-x-[52px] sm:-translate-x-7 py-2 px-7 justify-center align-middle inline-flex sm:ml-2.5 gap-2 rounded-md'><img alt='Logout' src={Logout} /> <p className="whitespace-nowrap">Cerrar Sesión</p></div>
                        </div>
                    </Transition.Child>
                    <div className="flex-shrink-0 w-32" aria-hidden="true">
                        {/* Dummy element to force sidebar to shrink to fit close icon */}
                    </div>
                </Dialog>
            </Transition.Root>
            <div className="hidden sm:flex flex-col space-y-4 px-16 pt-20">
                <Modal content={logout} open={openLogout} setOpen={setOpenLogout} />
                <img alt='Logo' className="mb-8" src={PiensaLogo} />
                {tabs.map((element, idx) => (
                    <Link key={element.name} to={element.href} className="flex flex-row">
                        <div className="w-6 h-6">{idx === selected ? element.iconActive : element.icon}</div>
                        <p className={classNames(idx === selected ? '' : 'text-[#4B5563]', "ml-4 font-medium")}>{element.name}</p>
                    </Link>
                ))}
                <p className="border-t pt-4 translate-y-2 pb-2 font-bold text-[#4B5563] border-[#4B5563]">Redes sociales</p>
                {social.map((element, idx) => (
                    <a href={element.href} target="_blank" rel="noreferrer noopener" key={element.name} className="flex flex-row">
                        <div className="w-6 h-6">{element.icon}</div>
                        <p className="ml-2 font-medium text-[#4B5563]">{element.name}</p>
                    </a>
                ))}
                <p className="border-t pt-4 translate-y-2 pb-2 font-bold text-[#4B5563] border-[#4B5563]">Soporte</p>
                <a href='https://wa.me/+595986366060' target="_blank" rel="noreferrer noopener" key='Solicitar Ayuda' className="flex flex-row">
                    <div className="w-6 h-6">{<img alt='Help' src={Help} />}</div>
                    <p className="ml-2 font-medium text-[#4B5563]" >Solicitar Ayuda</p>
                </a>
                <div onClick={() => handleLogout()} className='cursor-pointer border-0 shadow-none text-red -translate-x-7 py-2 px-7 justify-center align-middle inline-flex ml-2.5 gap-2 rounded-md'><img alt='Logout' src={Logout} /> <p className="whitespace-nowrap">Cerrar Sesión</p></div>
            </div >
        </>
    )
}
