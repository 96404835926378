import { Routes, Route, BrowserRouter as Router, Navigate } from 'react-router-dom';
import { useAuth } from './context/authContext';
import Cursos from './pages/cursos';
import Estudiantes from './pages/estudiantes';
import Facturacion from './pages/facturacion';
import EditFacturacion from './pages/editFacturacion';
import Registrar from './pages/registrar';
import Registro from './pages/registro';
import Pagos from './pages/pagos';
import Perfil from './pages/perfil';
import Resumen from './pages/resumen';
import Seleccion from './pages/seleccion';
import Tarjetas from './pages/tarjetas';
import Producto from './pages/productos';
import Home from './pages/home';
import Login from './pages/login';
import ResetPassword from './pages/resetpassword';
import Recuperacion from './pages/recuperacion';

function App() {

  const auth = useAuth();

  const authenticatedRoutes = (
    <Routes>
      <Route exact path="/cursos" element={<Cursos />} />
      <Route exact path="/estudiantes" element={<Estudiantes />} />
      <Route exact path="/facturacion" element={<Facturacion />} />
      <Route exact path="/facturacion/editar" element={<EditFacturacion />} />
      <Route exact path="/pagos" element={<Pagos />} />
      <Route exact path="/perfil" element={<Perfil />} />
      <Route exact path="/resumen" element={<Resumen />} />
      <Route exact path="/seleccion" element={<Seleccion />} />
      <Route exact path="/tarjetas" element={<Tarjetas />} />
      <Route path="/productos/:productId" element={<Producto />} />
      <Route path="/login" element={<Login />} />
      <Route path="/registrar" element={<Registrar />} />
      <Route path="/recuperacion" element={<Recuperacion />} />
      <Route path="/reset-password/:uidb64/:token" element={<ResetPassword />} />
      <Route path="*" element={<Navigate to="/cursos" />} />
    </Routes>
  );

  return (
    <Router>
      {auth.isAuthenticated ?
        authenticatedRoutes
        : (
          <Routes>
            <Route path="*" element={<Navigate to="/inicio" />} />
            <Route path="/inicio" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route exact path="/registrar" element={<Registrar />} />
            <Route exact path="/registro" element={<Registro />} />
            <Route path="/recuperacion" element={<Recuperacion />} />
            <Route path="/reset-password/:uidb64/:token" element={<ResetPassword />} />
            <Route path="/productos/:productId" element={<Producto />} />
          </Routes>
        )}
    </Router>
  );

}

export default App;
