import { useState } from 'react'
import Button from '../components/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import PiensaLogo from '../assets/PiensaLogo.svg';
import VectorIcon from '../assets/VectorIcon.svg';
import SphereIcon from '../assets/SphereIcon.svg';
import AxisIcon from '../assets/AxisIcon.png';
import LightbulbIcon from '../assets/LightbulbIcon.png';
import TextInput from '../components/textInput'
import PasswordInput from '../components/passwordInput'
import { useNavigate, Link, useParams } from 'react-router-dom';
import { useAuth } from '../context/authContext';
import api from '../services/api';
import { Helmet } from 'react-helmet';

export default function Recuperacion() {

    const navigate = useNavigate();
    const [error, setError] = useState('');
    const [password, setPassword] = useState(undefined);
    const [confirmPassword, setConfirmPassword] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const { uidb64 } = useParams();
    const { token } = useParams();
    const [success, setSuccess] = useState(false);
    const handlePassword = (field, value) => setPassword(value);
    const handleConfirmPassword = (field, value) => setConfirmPassword(value);
    const handleError = (field, value) => setError((prev) => ({ ...prev, [field]: value }));

    const handleSubmit = () => {
        setLoading(true)
        api.reset_password.modifyPassword(uidb64, token, password, confirmPassword).then((response) => {
            setLoading(false)
            setSuccess(true)
        }).catch((error) => { setError(error.data), setLoading(false) })
    }

    return (
        <>
            <Helmet>
                <meta property="og:image" content={`${process.env.REACT_APP_DOMAIN}/Thumbnail.png`} />
            </Helmet>
            <img className='hidden sm:block absolute left-[20%] top-[20%]' src={VectorIcon} alt='Vector' />
            <img className='hidden sm:block absolute right-[25%] top-[10%]' src={SphereIcon} alt='Sphere' />
            <img className='hidden sm:block absolute right-[15%] bottom-[5%]' src={AxisIcon} alt='Axis' />
            <img className='hidden sm:block absolute left-[10%] bottom-[5%]' src={LightbulbIcon} alt='Lightbulb' />
            <div className='bg-gray min-h-screen px-8 py-20'>
                <a href='https://piensa.la/' className='border-red text-red absolute top-4 sm:top-16 left-4 sm:left-16 py-2 px-7 justify-center align-middle inline-flex border rounded-md shadow-sm'><div className='flex gap-2'><div className='w-5'><FontAwesomeIcon icon={faArrowLeft} size="xs" /></div><p>Volver a piensa.la</p></div></a>
                <div className='w-32 h-32 mx-auto '>
                    <img src={PiensaLogo} alt='Piensa Logo' />
                </div>
                <div className='bg-white rounded-xl w-fit px-6 sm:px-24 py-6 sm:py-24 mx-auto'>
                    <p className='text-primary text-2xl sm:text-3xl font-extrabold text-center -mt-12 sm:-mt-8'>Recuperar contraseña</p>
                    {!success && <div className='mt-4 sm:mt-10 mx-auto max-w-xs'>
                        <PasswordInput error={error.password} name='password' value={password} handleChange={handlePassword} className='rounded' placeholder='Ingresa tu nueva contraseña' />
                        <PasswordInput error={error.confirmPassword} name='confirmPassword' value={confirmPassword} handleChange={handleConfirmPassword} className='rounded' containerClassName='mt-4' placeholder='Confirma tu contraseña' />
                        <Button onClick={() => handleSubmit()} content={loading ? <div className="w-5 h-5 mx-5 border-b-2 border-white rounded-full animate-spin" /> : 'Aceptar'} className='bg-primary border-primary text-white w-full mt-6 py-2 px-7 justify-center align-middle inline-flex border rounded-md shadow-sm' />
                        {error?.nonFieldErrors && <p className='text-red mt-2 text-center'>{error?.nonFieldErrors}</p>}
                    </div>}
                    {success && <div className='bg-gray py-2.5 px-5 w-fit text-center mx-auto rounded-md border mt-4'>
                        <p className='text-[primary]'>Se ha modificado exitosamente su contraseña.</p>
                        <Link to='/'>
                            <p className="text-primary font-bold cursor-pointer">Volver al panel de administración.</p>
                        </Link>
                    </div>}
                </div>
            </div>
        </>
    )
}
